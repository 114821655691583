import { ThreeEvent } from '@react-three/fiber';
import React, { FunctionComponent, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDesignerState, IDomIdSelectors } from '../../../../typings';
import FloatingInput from '../FloatingInput/FloatingInput';
import SelectedEntityPositionHotspot from '../Hotspots/SelectedEntityPositionHotspot';
import { IButton, IButtonReactProps, IButtonSubCategory, ITuple2, ITuple3, ITuple4 } from '../r3f-components/component-data-structure';
import { getUserDataFromIntersections, isHighestRenderOrder } from '../r3f-components/utils/general';
import EntityBoundaries from './EntityBoundaries/EntityBoundaries';
import { useGetHocBorderProperties, useGetHocCurvedProperties, useGetHocSpatialProperties, useGetHocTextProperties, useHocPointerDown, useHocPointerMove, useHocPointerUp } from './hocCustomHooks';
import { getEntityRenderOrder } from './hocUtils';
import { IOnTextSync } from '../r3f-components/components/DreiText';
import { onSetMultipleComponentProps_Cn_Doc } from '../../../store/actions';
import { store } from '../../../store';

interface IButtonWrapped {
	id: string;
	enabled?: boolean;
}

const sleep =  (milliseconds: number) => {
	return new Promise(resolve => setTimeout(resolve, milliseconds));
}
	

const withButtonBehaviour = (WrappedButton: FunctionComponent<IButtonReactProps>): FunctionComponent<IButtonWrapped> => {
	const FuncComp: FunctionComponent<IButtonWrapped> = ({ enabled: entityIsEnabled = true, id }) => {
		const dispatch = useDispatch();

		// Selectors
		const showFloatingInput = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds?.length === 1);
		const entity = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById[id] as IButton);
		const projectShadowsEnabled = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.shadowsEnabled ?? true);		
		const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
		const transformControlsMode = useSelector((state: IDesignerState) => state.userReducer.transformControlsMode);

		// Refs
		const pointerStageRef = useRef<'down' | 'move' | 'up' | null>(null);
		const shiftLockedRef = useRef<number | null>(null);
		const initialPosRef = useRef<ITuple3 | null>(null); // Used to calculate pointer offset in pointerMove
		const previousPositionRef = useRef<ITuple3 | null>(null); // The previous position, used to compare with the current position
		const pointerUpHasRunRef = useRef<boolean | null>(null); // To prevent multiple dispatched actions when pointerUp runs twice this is reset onPointerMove
		const canvasRef = useRef<HTMLCanvasElement>(document.getElementById(IDomIdSelectors.zapparCanvas) as HTMLCanvasElement);
		const [doubleClicked, setDoubleClicked] = useState(false); // For text / button entities, to display FloatingInput
		const [showEntityPositionHotspot, setShowEntityPositionHotspot] = useState(false);
		const visibleTextScaleRef = useRef<ITuple2>();
		const initialBtnToTxtScaleRatiosRef = useRef<ITuple2>();

		// Entity properties to pass to wrapped component
		const renderOrder = getEntityRenderOrder(entity);
		const { opacity, text, fontFamily, fontSize, textAlignment, svgUrl, textureUrl, category, subCategory, scale: nonTransientScale, castShadow = false, receiveShadow = false } = entity;
		const { position, scale, rotation } = useGetHocSpatialProperties(entity);
		const { fontRgba, color } = useGetHocTextProperties(entity);
		const { borderWidth, borderRadius, borderRgba } = useGetHocBorderProperties(entity);
		const { curvature } = useGetHocCurvedProperties(entity);
		const isSnapshotUi = subCategory === IButtonSubCategory.recording || subCategory === IButtonSubCategory.snapshot;

		// Pointer events
		const onPointerDown = useHocPointerDown({ pointerStageRef, initialPosRef, entity, setShowEntityPositionHotspot });
		const onPointerMove = useHocPointerMove({ pointerStageRef, initialPosRef, pointerUpHasRunRef, previousPositionRef, entity, shiftLockedRef });
		const onPointerUp = useHocPointerUp({ pointerStageRef, pointerUpHasRunRef, initialPosRef, entity, setShowEntityPositionHotspot, shiftLockedRef });
		const onPointerOver = () => (canvasRef.current.style.cursor = 'text');
		const onPointerOut = () => (canvasRef.current.style.cursor = 'default');
		const onDoubleClick = (e: ThreeEvent<MouseEvent>) => {
			if (!isHighestRenderOrder({userDataArray: getUserDataFromIntersections(e.intersections), renderOrder})) return;
			setDoubleClicked(true);
		};

		const floatingInput =
			showFloatingInput && doubleClicked ? <FloatingInput position={position} text={entity.text} onBlur={() => setDoubleClicked(false)} onUnmount={() => setDoubleClicked(false)} /> : undefined;

		useEffect(() => {
			if (!visibleTextScaleRef.current) return;
			//console.log('update initial btn to text scale ratio')
			const _scale = (store.getState().contentReducer.contentDoc.componentsById[entity.id] as IButton).scale;
			initialBtnToTxtScaleRatiosRef.current = [visibleTextScaleRef.current[0] / _scale[0], visibleTextScaleRef.current[1] / _scale[1]];
		}, [selectedEntityIds?.includes(entity.id), transformControlsMode])

	
		return (
			<>
				<EntityBoundaries
					position={position}
					rotation={rotation}
					scale={scale}
					renderOrder={renderOrder}
					onPointerDown={entityIsEnabled ? onPointerDown : undefined}
					onPointerUp={onPointerUp}
					label={`Button component ${id} render order ${renderOrder}`}
					floatingInput={floatingInput}
				>
					<WrappedButton
						id={id}
						category={category}
						scale={scale}
						nonTransientScale={nonTransientScale}
						rotation={rotation}
						position={position}
						renderOrder={renderOrder}
						svgUrl={svgUrl}
						textureUrl={textureUrl}
						opacity={opacity}
						borderWidth={borderWidth}
						borderRgba={borderRgba}
						borderRadius={borderRadius}
						textAlignment={textAlignment}
						fontRgba={fontRgba}
						fontFamily={fontFamily}
						fontSize={fontSize}
						text={text}
						color={color}
						isTextScaleLocked={false}
						//isTextScaleLocked={entity.isTextScaleLocked}
						castShadow={castShadow && projectShadowsEnabled}
						receiveShadow={receiveShadow && projectShadowsEnabled}
						curvature={curvature}
						onPointerDown={entityIsEnabled && !isSnapshotUi ? onPointerDown : undefined}
						onPointerUp={entityIsEnabled ? onPointerUp : undefined}
						onPointerOver={onPointerOver}
						onPointerOut={onPointerOut}
						onDoubleClick={onDoubleClick}
					/>
				</EntityBoundaries>
				{showEntityPositionHotspot && <SelectedEntityPositionHotspot onPointerMove={showEntityPositionHotspot ? onPointerMove : undefined} onPointerUp={entityIsEnabled ? onPointerUp : undefined} />}
			</>
		);
	};
	return FuncComp;
};

export default withButtonBehaviour;
