import { WebGLRenderer } from "three";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { KTX2Loader } from "three/examples/jsm/loaders/KTX2Loader";


// It is recommended to always pull your Draco JavaScript and WASM decoders
// from this URL. Users will benefit from having the Draco decoder in cache
// as more sites start using the static URL.
// const dracoLoader = new DRACOLoader().setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.4.1/');

// const gltfLoader = new GLTFLoader().setCrossOrigin('anonymous').setDRACOLoader(dracoLoader);
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('https://d1mfzu0xo6h6ih.cloudfront.net/designer/draco/1.5.6/');
dracoLoader.setCrossOrigin('anonymous');
dracoLoader.preload();
export const gltfLoader = new GLTFLoader();
gltfLoader.setDRACOLoader(dracoLoader);
(gltfLoader as any).setCrossOrigin('anonymous');

const ktx2Loader = new KTX2Loader();
ktx2Loader.setTranscoderPath('https://d1mfzu0xo6h6ih.cloudfront.net/designer/ktx2/7ce26e9/');
const renderer = new WebGLRenderer();
ktx2Loader.detectSupport( renderer );
gltfLoader.setKTX2Loader(ktx2Loader);

/**
 * Converts a number of bytes to a human-readable string with a file size suffix.
 *
 * @param bytes The file size in bytes.
 * @returns The human-readable string representation of the size.
 */
export function formatBytes(bytes: number): string {
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const suffixes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${suffixes[i]}`;
}
