import React, { memo, useEffect, useLayoutEffect, useState } from 'react';
import { BackSide, LinearSRGBColorSpace, MathUtils, Shader, SRGBColorSpace, Texture } from 'three';
import { IChromaKey } from '../component-data-structure';
import { useHlsLoader } from '../hooks';
import { return360VideoIdBySceneId } from '../utils/content360';
import { getMaterial } from '../utils/getMaterial';
import { CustomEnvironment } from './Lighting/CustomEnvironment';
import LoadingSphere from './LoadingSphere';

type Background360Media = { imageTexture?: Texture, videoUrl?: string }

type IParentProps = JSX.IntrinsicElements['mesh'] & {
    media: Background360Media
    radius?: number;
    sceneId: string;
    loop?: boolean;
    muted?: boolean;
    enable360Environment?: boolean;
    environmentRenderPriority?: number;
    hasAlpha?: boolean;
    stackedAlpha?: boolean;
    videoStatus?: "error" | "pending" | "ready" | "complete"; // needed to trigger a re-render when to create new hls & video node
    chromaKey?: IChromaKey;
    audioContext?: AudioContext;
    forceClearVideoCache?: boolean;
    enableVideoPlay?: boolean;
    audioStreamDestination?: MediaStreamAudioDestinationNode;
    onVideoNodeReady?: ((video?: HTMLVideoElement) => unknown);
    onTextureReady?: () => unknown;
};

const isSafari = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;

const Background360: React.FunctionComponent<IParentProps> = ({ media, radius = 60, loop = false, muted = false, onTextureReady, enable360Environment = false, audioContext, audioStreamDestination, hasAlpha = false, stackedAlpha = false, chromaKey, onVideoNodeReady, sceneId, environmentRenderPriority, videoStatus, enableVideoPlay = true, ...meshProps }) => {
    if (typeof media.videoUrl === 'undefined' && typeof media.imageTexture === 'undefined') {
        console.error('Background360: No videoUrl or imageTexture provided');
        throw new Error('Background360: No videoUrl or imageTexture provided');
    }

    const [videoDataLoaded, setVideoDataLoaded] = useState(false);
    const [videoTexture, video] = useHlsLoader(media.videoUrl || '', return360VideoIdBySceneId(sceneId), {
		muted,
		loop,
		autoplay: true,
		preventVideoLoad: !Boolean(media.videoUrl),
		audioContext,
		audioStreamDestination
	})

    useEffect(() => {
        setVideoDataLoaded(false);
        const loadedDataListener = () => {
            setVideoDataLoaded(true);
        };
        video?.addEventListener('loadeddata', loadedDataListener);
        return () => video?.removeEventListener('loadeddata', loadedDataListener);
    }, [video])

    useEffect(() => {
        if (!videoTexture && !media.imageTexture) return;
        onTextureReady?.();
    }, [videoTexture, media.imageTexture])

    useLayoutEffect(() => {
        if (videoTexture) {
            onVideoNodeReady?.(video);
            videoTexture.colorSpace = hasAlpha ? LinearSRGBColorSpace : SRGBColorSpace;
            if (enableVideoPlay && video) {
                video.play()
            };
        }
        return () => {
            if (video) video.currentTime = 0;
        }
    }, [videoTexture, media.videoUrl])

    const onBeforeCompile = (shader: Shader) => {
		if (!isSafari) return;
		//console.log('OnbeforeCompile video texture');
		shader.fragmentShader = shader.fragmentShader.replace(
			'#include <map_fragment>',
			`
			#ifdef USE_MAP
			
				vec4 sampledDiffuseColor = texture2D( map, vMapUv );
			
				// inline sRGB decode
				sampledDiffuseColor = vec4( mix( pow( sampledDiffuseColor.rgb * 0.9478672986 + vec3( 0.0521327014 ), vec3( 2.4 ) ), sampledDiffuseColor.rgb * 0.0773993808, vec3( lessThanEqual( sampledDiffuseColor.rgb, vec3( 0.04045 ) ) ) ), sampledDiffuseColor.a );
		
				diffuseColor *= sampledDiffuseColor;
		
			#endif
			`
		);
	};

    return (
       <>
            {enable360Environment && <CustomEnvironment near={1} far={1000} resolution={256} frames={Infinity} renderPriority={environmentRenderPriority}>
                <mesh {...meshProps} rotation={[0, MathUtils.degToRad(270), 0]} scale={[-1, 1, 1]}>
                    <sphereGeometry args={[radius, 60, 40]} />
                    {getMaterial({ opacity: 1, side: BackSide, innerColor: undefined, hasAlpha, stackedAlpha, videoTexture: videoTexture?.clone(), onBeforeCompile, imageTexture: media.imageTexture?.clone(), chromaKey })}
                </mesh>
            </CustomEnvironment>}
           {(videoDataLoaded || (video?.readyState ?? 0) >= 2 || media.imageTexture)? 
                <mesh {...meshProps} rotation={[0, MathUtils.degToRad(270), 0]} scale={[-1, 1, 1]} renderOrder={-99999999}>
                    <sphereGeometry args={[radius, 60, 40]} />
                    {getMaterial({ opacity: 1, side: BackSide, innerColor: undefined, hasAlpha, stackedAlpha, videoTexture: videoTexture?.clone(), onBeforeCompile, imageTexture: media.imageTexture?.clone(), chromaKey })}
                </mesh> :
                <LoadingSphere radius={radius} renderOrder={-99999999}/>
            }
        </>

    );
}

Background360.displayName = 'background360'
export default memo(Background360);