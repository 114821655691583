/* eslint-disable @typescript-eslint/ban-ts-comment */

import { DEFAULT_LAUNCH_SCREEN } from '@/settings';
import * as Automerge from '@automerge/automerge';
import * as Sentry from '@sentry/react';
import { MathUtils } from 'three';
import { IContentReducer } from '../../../typings';
import {
	IAbstractComponentUnion,
	IActionCategory,
	IAreaTypes,
	IBaseTargetAction,
	IButton,
	IComponentsById,
	IComponentType,
	IComponentUnion,
	IContentDoc,
	IEmitter,
	IFaceLandmarkGroup,
	IImage,
	IImageTrackingOrientation,
	IModel3d,
	initCnDoc,
	IRoot,
	ISceneComp,
	IScreenAnchorGroup,
	IScreenContent,
	ISpatialComponentActionData,
	ISpatialComponentUnion,
	ITargetActionUnion,
	ITrackingContentDocByTrackingType,
	ITrackingImageData,
	ITrackingTypes,
	ITransitionEffectTypes,
	ITriggerTypes,
	IUnitTypes,
	IVideo,
	ROOT_COMPNENT_ID,
} from '../../components/r3f/r3f-components/component-data-structure';
import { IImageBackground360, IVideoBackground360 } from '../../components/r3f/r3f-components/component-data-structure/types/background360';
import { ITargetImageReferenceObject } from '../../components/r3f/r3f-components/component-data-structure/types/targetImageReferenceObject';
import { isAbstractComponent, isAbstractComponentType } from '../../components/r3f/r3f-components/utils/general';
import { cdsClient } from '../../sync/cds';
import { adjustRenderOrdersOnEntityRemoval, appendImg, appendM3u8, getHighestActiveRenderOrder, getParentSceneId, isSceneContainsSpatialArComponent, prependHttps } from '../../utils/general';
import { getComponentParentIdById } from '../../utils/pure';
import { copyAbstractComponent, copySpatialComponent } from '../../utils/pure/copy';
import { createFaceLandmarkGroup, createScreenAnchorGroup } from '../../utils/pure/create';
import { generateOldToNewIdDict } from '../../utils/pure/generateOldToNewIdDict';
import { getEntityDropTitle, getParentIdForSpatialComponentOnPaste, getSceneTitles, getScreenContentIdBySceneId } from '../../utils/pure/get';
import { insertAbstractComponent } from '../../utils/pure/insertAbstractComponent';
import { insertParentForSpatialComponentIfRequired } from '../../utils/pure/insertParentForSpatialComponentIfRequired';
import { insertSpatialComponent } from '../../utils/pure/insertSpatialComponent';
import { IOnClearScreenSceneContent_Cn_Doc_Action, IOnCopyScreenSceneContent_Cn_Doc_Action, IOnReparentComponent_Cn_Doc_Action } from '../actions';
import {
	IChange_Cn_Doc_Action,
	IContentDocActionTypes,
	IOnAddAnchorGroupToScene_Cn_Doc_Action,
	IOnAddComponentAction_Cn_Doc_Action,
	IOnAddEntity_Cn_Doc_Action,
	IOnAddFaceLandmarkToScene_Cn_Doc_Action,
	IOnCopyScenesAtIndex_Cn_Doc_Action,
	IOnRemoveComponentAction_Cn_Doc_Action,
	IOnRemoveEntities_Cn_Doc_Action,
	IOnRemoveIdleAnimation_Cn_Doc_Action,
	IOnRemoveIdlePoseTime_Cn_Doc_Action,
	IOnRemoveImageTracking_Cn_Doc_Action,
	IOnRemoveSceneReferences_Cn_Doc_Action,
	IOnSetActiveSceneChildren_Cn_Doc_Action,
	IOnSetAnalyticsTracking_Cn_Doc_Action,
	IOnSetChromaKeyProperties_Cn_Doc_Action,
	IOnSetComponentAction_Cn_Doc_Action,
	IOnSetComponentTransition_Cn_Doc_Action,
	IOnSetDisableHLSvideoStreaming_Cn_Doc_Action,
	IOnSetEmitterBaseProperties_Cn_Doc_Action,
	IOnSetEmitterGroupProperties_Cn_Doc_Action,
	IOnSetActionTrigger_Cn_Doc_Action,
	IOnSetEmitterProperties_Cn_Doc_Action,
	IOnSetImageTracking_Cn_Doc_Action,
	IOnSetInitialTemplateSlug_Cn_Doc_Action,
	IOnSetMultipleComponentProps_Cn_Doc_Action,
	IOnSetPositions_Cn_Doc_Action,
	IOnSetProjectColors_Cn_Doc_Action,
	IOnSetProjectShadowsEnabled_Cn_Doc_Action,
	IOnSetRgbaColors_Cn_Doc_Action,
	IOnSetRootChildren_Cn_Doc_Action,
	IOnSetRotations_Cn_Doc_Action,
	IOnSetScales_Cn_Doc_Action,
	IOnSetTargetImageReferenceObject_Cn_Doc_Action,
	IOnSetWebEmbed_Cn_Doc_Action,
	IPasteCopiedEntities_Cn_Doc_Action,
	IReplaceEntity_Cn_Doc_Action,
	IOnSetDevice_Cn_Doc_Action,
	IOnSetScene360Background_Cn_Doc_Action,
	IOnSetScene360InitialCameraRotation_Cn_Doc_Action,
	IOnSetVideoRenditionProgressData_Cn_Doc_Action,
	IonSetLaunchScreenField_Cn_Doc_Action,
	IonSetLaunchScreenId_Cn_Doc_Action,
	IOnAddMediaFile_Cn_Doc_Action,
	IOnSetGrabnGoOptions_Cn_Doc_Action
} from '../actions/action-types';

const initialState: IContentReducer = {
	contentDoc: initCnDoc(),
};

// conditionally update docs
const updateContentDocFromBackend = (state: IContentReducer, action: IChange_Cn_Doc_Action): IContentReducer => {
	return {
		...state,
		contentDoc: action.contentDoc,
	};
};

const addEntityContentDoc = (state: IContentReducer, action: IOnAddEntity_Cn_Doc_Action): IContentReducer => {
	const { parentId, entity } = action.payload;

	const contentDoc = cdsClient.changeContentDoc((doc) => {
		(doc.componentsById[parentId] as IAbstractComponentUnion).children.push(entity.id);
		doc.componentsById[entity.id] = entity;
	});

	return {
		...state,
		contentDoc,
	};
};

const setPositionsInContentDoc = (state: IContentReducer, action: IOnSetPositions_Cn_Doc_Action): IContentReducer => {
	const positionDict = action.payload;
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		for (const id in positionDict) {
			(doc.componentsById[id] as ISpatialComponentUnion).position = positionDict[id];
		}
	});

	return {
		...state,
		contentDoc,
	};
};

const setRgbaColorsInContentDoc = (state: IContentReducer, action: IOnSetRgbaColors_Cn_Doc_Action): IContentReducer => {
	const colorDict = action.payload;
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		for (const id in colorDict) {
			if (doc.componentsById[id].type !== IComponentType.Button) continue;
			(doc.componentsById[id] as IButton).color = colorDict[id];
		}
	});

	return {
		...state,
		contentDoc,
	};
};

const setRotationsInContentDoc = (state: IContentReducer, action: IOnSetRotations_Cn_Doc_Action): IContentReducer => {
	const rotationDict = action.payload;
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		for (const id in rotationDict) {
			(doc.componentsById[id] as ISpatialComponentUnion).rotation = rotationDict[id];
		}
	});

	return {
		...state,
		contentDoc,
	};
};

const setScalesInContentDoc = (state: IContentReducer, action: IOnSetScales_Cn_Doc_Action): IContentReducer => {
	const scaleDict = action.payload;
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		for (const id in scaleDict) {
			const component = doc.componentsById[id];
			(doc.componentsById[id] as ISpatialComponentUnion).scale = scaleDict[id];
			if ('scalesInverted' in component) {
				component.scalesInverted = [scaleDict[id][0] < 0, scaleDict[id][1] < 0];
			}
		}
	});

	return {
		...state,
		contentDoc,
	};
};

const setActiveSceneChildren = (state: IContentReducer, action: IOnSetActiveSceneChildren_Cn_Doc_Action): IContentReducer => {
	const { activeSceneId, sceneChildren } = action.payload;
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		const scene = doc.componentsById[activeSceneId];
		if (scene.type === IComponentType.Scene) {
			scene.children = sceneChildren;
		}
	});
	return {
		...state,
		contentDoc,
	};
};

const onSetRootChildren = (state: IContentReducer, action: IOnSetRootChildren_Cn_Doc_Action): IContentReducer => {
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		const rootComponent = doc.componentsById[doc.rootComponentId] as IRoot;
		rootComponent.children = action.payload;
	});
	return {
		...state,
		contentDoc,
	};
};

const addImageTracking_Cn_Doc = (state: IContentReducer, action: IOnSetImageTracking_Cn_Doc_Action): IContentReducer => {
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		if (!doc.tracking) doc.tracking = {} as ITrackingContentDocByTrackingType;
		let currentImageTrackingData = doc.tracking[ITrackingTypes.image] ? JSON.parse(JSON.stringify(doc.tracking[ITrackingTypes.image])) : ({} as ITrackingImageData);
		// Set defaults if nothing there
		if (!currentImageTrackingData) {
			currentImageTrackingData = {
				orientation: IImageTrackingOrientation.upright,
				units: IUnitTypes.mm,
				aspectRatio: 1 / 1.41,
				opacity: 1,
				scale: [1, 1, 1],
				targetAreaData: {
					heightInMm: 297,
					targetArea: IAreaTypes.a4,
				},
			} as ITrackingImageData;
		}
		doc.tracking[ITrackingTypes.image] = { ...currentImageTrackingData, ...action.payload }
	})

	return { ...state, contentDoc }
}

const removeComponentsFromContentDoc = (state: IContentReducer, action: IOnRemoveEntities_Cn_Doc_Action): IContentReducer => {
	const { ids: componentIds } = action.payload;
	if (!componentIds.length) return state;
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		for (let i = 0; i < componentIds.length; i++) {
			const componentId = componentIds[i];

			// Remove actions that are linked to deleted entity via target ids
			for (const id in doc.componentsById) {
				const component = doc.componentsById[id] as ISpatialComponentUnion;
				if (!component.actions) continue;

				const componentActions = component.actions;
				for (const triggerType in component.actions) {
					const actionArray = componentActions[triggerType as keyof typeof componentActions];
					if (!actionArray) continue;
					for (let i = 0; i < actionArray.length; i++) {
						const action = actionArray[i];
						const { targetIds } = action as IBaseTargetAction;
						if (!targetIds) continue;
						if (targetIds.includes(componentId)) {
							delete actionArray[i];
						}
					}
				}
			}
		}

		// Remove deleted component from document
		for (let i = 0; i < componentIds.length; i++) {
			const componentId = componentIds[i];

			// adjust render order for other entities
			adjustRenderOrdersOnEntityRemoval(doc.componentsById, componentId);

			// remove component
			if (doc.componentsById[componentId]) delete doc.componentsById[componentId];

			// remove component id from children of other components
			for (const id in doc.componentsById) {
				const { children } = doc.componentsById[id] as IAbstractComponentUnion;
				if (!children) continue;
				(doc.componentsById[id] as IAbstractComponentUnion).children = children.filter((id) => componentId !== id);
			}
		}
	});

	return {
		...state,
		contentDoc,
	};
};

const setComponentTransition_Cn_Doc = (state: IContentReducer, action: IOnSetComponentTransition_Cn_Doc_Action): IContentReducer => {
	const { ids, category, type, duration, delay } = action.payload;
	if (!ids) return state;
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		for (let i = 0; i < ids.length; i++) {
			if (doc.componentsById[ids[i]].type === IComponentType.Root) continue;
			const component = doc.componentsById[ids[i]] as ISpatialComponentUnion | ISceneComp;

			// to keep content doc lean delete transition data if noEffect selected
			if (type === ITransitionEffectTypes.noEffect) {
				// if no transition data doc no need to delete data
				if (typeof component.transitions !== 'object') continue;
				const transDataEmpty = Object.keys(component.transitions).length === 0;
				// if data for trans category exists delete
				if (!transDataEmpty) delete component.transitions[category];
				// else delete whole transition object
				else {
					delete component.transitions;
				}
			} else {
				if (!component.transitions) component.transitions = {};
				component.transitions[category] = { type, duration, delay };
			}
		}
	});
	return {
		...state,
		contentDoc,
	};
};

const replaceImage = (entity: IImage, replacementEntity: IImage) => {
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		// Retain current title if the user has changed it
		// If currentTitle = someName.png or someName.jpeg then assume not changed
		const currentEntity = doc.componentsById[entity.id as string] as IImage;
		const currentTitle = currentEntity.title;
		if (currentTitle.split('')[currentTitle.length - 4] !== '.' && currentTitle.split('')[currentTitle.length - 5] !== '.') {
			replacementEntity.title = currentEntity.title;
		}

		// Adjust the largest dimension to fit that of the respective current entity dimension
		const [x, y] = replacementEntity.scale;
		const aspectRatio = x / y;
		if (x > y) {
			replacementEntity.scale[0] = currentEntity.scale[0];
			replacementEntity.scale[1] = replacementEntity.scale[0] / aspectRatio;
		} else if (y > x) {
			replacementEntity.scale[1] = currentEntity.scale[1];
			replacementEntity.scale[0] = replacementEntity.scale[1] * aspectRatio;
		}

		// Retain previous position, rotation, curvature
		replacementEntity.position = currentEntity.position;
		replacementEntity.rotation = currentEntity.rotation;
		replacementEntity.curvature = currentEntity.curvature;
		replacementEntity.isSnappedToTarget = currentEntity.isSnappedToTarget;
		(doc.componentsById[entity.id as string] as IImage) = {
			...JSON.parse(JSON.stringify(currentEntity)),
			...JSON.parse(JSON.stringify(replacementEntity)),
			id: currentEntity.id,
		};
	});
	return contentDoc;
};

const replaceModel3d = (entity: IModel3d, replacementEntity: IModel3d) => {
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		// Retain current title if the user has changed it
		// If currentTitle = someName.glb or someName.gltf then assume not changed
		const currentEntity = doc.componentsById[entity.id as string] as IModel3d;
		const currentTitle = currentEntity.title;
		if (currentTitle.split('')[currentTitle.length - 4] !== '.' && currentTitle.split('')[currentTitle.length - 5] !== '.') {
			replacementEntity.title = currentEntity.title;
		}

		// // Adjust the largest dimension to fit that of the respective current entity dimension
		const indexArray = [0, 1, 2];
		const largedDimensionIndex = replacementEntity.scale.indexOf(Math.max(...replacementEntity.scale));
		const adjFactor = currentEntity.scale[largedDimensionIndex] / replacementEntity.scale[largedDimensionIndex];
		indexArray.forEach((index) => {
			replacementEntity.scale[index] = replacementEntity.scale[index] * adjFactor;
		});

		// Retain previous position, rotation
		replacementEntity.position = currentEntity.position;
		replacementEntity.rotation = currentEntity.rotation;
		(doc.componentsById[entity.id as string] as IImage) = {
			...JSON.parse(JSON.stringify(currentEntity)),
			...JSON.parse(JSON.stringify(replacementEntity)),
			id: currentEntity.id,
		};

		// Remove linked animations if applicable
		for (let i = 0; i < Object.keys(doc.componentsById).length; i++) {
			const component = doc.componentsById[Object.keys(doc.componentsById)[i]] as ISpatialComponentUnion;
			if (!isAbstractComponent(component)) {
				for (const triggerType in component.actions) {
					const componentActions = component.actions[triggerType as ITriggerTypes];
					if (!componentActions) continue;
					for (let i = 0; i < componentActions.length; i++) {
						if (!('targetIds' in componentActions[i])) continue;
						if ((componentActions[i] as ITargetActionUnion).targetIds.includes(entity.id as string) && (componentActions[i] as ITargetActionUnion).type === IActionCategory.animateModel) {
							delete componentActions[i];
						}
					}
				}
			}
		}

		// Remove idle animation
		delete (doc.componentsById[entity.id as string] as IModel3d).idleAnimation;
	});
	return contentDoc;
};

const replaceVideo = (entity: IVideo, replacementEntity: IVideo) => {
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		// Retain current title if the user has changed it
		// If currentTitle = someName.mp4 or someName.webm then assume not changed
		const currentEntity = doc.componentsById[entity.id as string] as IVideo;
		const currentTitle = currentEntity.title;
		if (currentTitle.split('')[currentTitle.length - 4] !== '.' && currentTitle.split('')[currentTitle.length - 5] !== '.') {
			replacementEntity.title = (doc.componentsById[entity.id as string] as IVideo).title;
		}

		// Adjust the largest dimension to fit that of the respective current entity dimension
		const [x, y] = replacementEntity.scale;
		const aspectRatio = x / y;
		if (x > y) {
			replacementEntity.scale[0] = currentEntity.scale[0];
			replacementEntity.scale[1] = replacementEntity.scale[0] / aspectRatio;
		} else if (y > x) {
			replacementEntity.scale[1] = currentEntity.scale[1];
			replacementEntity.scale[0] = replacementEntity.scale[1] * aspectRatio;
		}

		// Retain previous position, rotation, curvature
		replacementEntity.position = currentEntity.position;
		replacementEntity.rotation = currentEntity.rotation;
		replacementEntity.curvature = currentEntity.curvature;
		replacementEntity.isSnappedToTarget = currentEntity.isSnappedToTarget;
		(doc.componentsById[entity.id as string] as IImage) = {
			...JSON.parse(JSON.stringify(currentEntity)),
			...JSON.parse(JSON.stringify(replacementEntity)),
			id: currentEntity.id,
		};
	});
	return contentDoc;
};

const replaceEntity = (state: IContentReducer, action: IReplaceEntity_Cn_Doc_Action): IContentReducer => {
	const { entity, replacementEntity } = action.payload;
	const entityCp = JSON.parse(JSON.stringify(entity));
	let contentDoc: Automerge.Doc<IContentDoc>;
	switch (entityCp.type) {
		case IComponentType.Image:
			contentDoc = replaceImage(entityCp, replacementEntity as IImage);
			break;
		case IComponentType.Model3d:
			contentDoc = replaceModel3d(entityCp, replacementEntity as IModel3d);
			break;
		case IComponentType.Video:
			contentDoc = replaceVideo(entityCp, replacementEntity as IVideo);
			break;
		default:
			throw new Error('Entity type not supported');
	}
	return { ...state, contentDoc }
};

const copyScenesAtIndex_Cn_Doc = (state: IContentReducer, action: IOnCopyScenesAtIndex_Cn_Doc_Action): IContentReducer => {
	let { index, adjustPositionToCamera } = action.payload;
	const { sceneIds } = action.payload;
	if (!sceneIds) return state;

	const contentDoc = cdsClient.changeContentDoc((doc) => {
		let componentsByIdCp = JSON.parse(JSON.stringify(doc.componentsById)) as IComponentsById;
		const sceneTitles = getSceneTitles(JSON.parse(JSON.stringify(componentsByIdCp)));
		for (let i = 0; i < sceneIds.length; i++) {
			const componentToCopy = componentsByIdCp[sceneIds[i]] as ISceneComp;
			const newTitle = getEntityDropTitle(sceneTitles, componentToCopy.title, false);
			const sourceSceneIsAr = true;
			const isFlatOrientation = doc?.tracking?.[ITrackingTypes.image]?.orientation === IImageTrackingOrientation.flat;
			const dict = generateOldToNewIdDict({ componentToCopy, componentsById: componentsByIdCp, parentId: ROOT_COMPNENT_ID });
			if (!dict) continue;
			const copy = copyAbstractComponent({ abstractComponent: componentToCopy, oldToNewIdDict: dict, componentsById: componentsByIdCp, parentId: ROOT_COMPNENT_ID });
			if (!copy) continue;
			const componentsByIdOut = insertAbstractComponent({
				abstractComponent: copy,
				parentId: ROOT_COMPNENT_ID,
				sourceSceneIsAr,
				isFlatOrientation,
				newTitle,
				componentsById: componentsByIdCp,
				abstractComponentId: sceneIds[i],
				adjustPositionToCamera
			});
			componentsByIdCp = JSON.parse(JSON.stringify(componentsByIdOut));

			const newSceneId = dict[sceneIds[i]];
			if (newSceneId) {
				if ((componentsByIdCp[ROOT_COMPNENT_ID] as IRoot).children && index > (componentsByIdCp[ROOT_COMPNENT_ID] as IRoot).children.length)
					index = (componentsByIdCp[ROOT_COMPNENT_ID] as IRoot).children.length;
				// The new scene has already been inserted at the end - need to remove the end one and insert it at the correct location
				(componentsByIdCp[ROOT_COMPNENT_ID] as IRoot).children?.splice(index, 0, newSceneId);
				(componentsByIdCp[ROOT_COMPNENT_ID] as IRoot).children?.splice((componentsByIdCp[ROOT_COMPNENT_ID] as IRoot).children.length - 1, 1);
			}
		}

		doc.componentsById = JSON.parse(JSON.stringify(componentsByIdCp));
	});

	return {
		...state,
		contentDoc,
	};
};

const copyScreenSceneContent = (state: IContentReducer, action: IOnCopyScreenSceneContent_Cn_Doc_Action): IContentReducer => {
	const { activeSceneId, targetSceneId } = action.payload;
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		const componentsByIdCp = JSON.parse(JSON.stringify(doc.componentsById)) as IComponentsById;
		const screenContentId = getScreenContentIdBySceneId(activeSceneId, componentsByIdCp);
		if (!screenContentId) return;
		const componentToCopy = componentsByIdCp[screenContentId] as IScreenContent;

		const dict = generateOldToNewIdDict({ componentToCopy, componentsById: componentsByIdCp, targetSceneId, parentId: activeSceneId });
		if (!dict) return;
		const copy = copyAbstractComponent({ abstractComponent: componentToCopy, oldToNewIdDict: dict, componentsById: componentsByIdCp, parentId: activeSceneId });
		if (!copy) return;
		const componentsByIdOut = insertAbstractComponent({
			abstractComponent: copy,
			abstractComponentId: screenContentId,
			parentId: targetSceneId,
			sourceSceneIsAr: true,
			isFlatOrientation: false,
			componentsById: componentsByIdCp,
			adjustPositionToCamera: false
		});
		if (!componentsByIdOut) return;

		doc.componentsById = JSON.parse(JSON.stringify(componentsByIdOut));
	});
	return {
		...state,
		contentDoc,
	};
};

const pasteCopiedEntitiesInContentDoc = (state: IContentReducer, action: IPasteCopiedEntities_Cn_Doc_Action): IContentReducer => {
	const { activeSceneId, copiedIds, offset, newTitle, isScreenRelativeMode } = action.payload;
	if (!copiedIds || !activeSceneId) return state;
	const isFlatOrientation = state?.contentDoc?.tracking?.[ITrackingTypes.image]?.orientation === IImageTrackingOrientation.flat;

	const contentDoc = cdsClient.changeContentDoc((doc) => {
		let componentsByIdCp = JSON.parse(JSON.stringify(doc.componentsById)) as IComponentsById;

		for (let i = 0; i < copiedIds.length; i++) {
			const componentToCopy = componentsByIdCp[copiedIds[i]] as ISpatialComponentUnion;
			// Did the entity come from an AR scene?
			const sourceSceneId = getParentSceneId(copiedIds[i], componentsByIdCp);
			if (!sourceSceneId) continue;
			const sourceSceneIsAr = isSceneContainsSpatialArComponent(sourceSceneId, copiedIds[i], componentsByIdCp);

			// When pasting, we may need to create a group ( screen anchor or face landmark ) for the spatial component to be inserted 
			const sourceParentId = getComponentParentIdById(componentToCopy.id, componentsByIdCp);
			if (!sourceParentId) continue;
			const sourceParentComponent = componentsByIdCp[sourceParentId] as ISceneComp | IScreenAnchorGroup | IFaceLandmarkGroup;
			const faceLandmark = sourceParentComponent.type === IComponentType.FaceLandmarkGroup ? sourceParentComponent.landmark : undefined;
			const screenAnchorGroupPosition = sourceParentComponent.type === IComponentType.ScreenAnchorGroup ? sourceParentComponent.anchorPositionType : undefined;
			const componentsByIdTemp = insertParentForSpatialComponentIfRequired({
				spatialComponent: componentToCopy,
				isScreenRelativeMode,
				isFlatOrientation,
				sourceSceneId,
				sourceSceneIsAr,
				componentsById: componentsByIdCp,
				targetSceneId: activeSceneId,
			});
			if (componentsByIdTemp) componentsByIdCp = JSON.parse(JSON.stringify(componentsByIdTemp)) as IComponentsById;

			// After the above step, there will be a parentId for the spatial component, if there wasn't already
			const parentId = getParentIdForSpatialComponentOnPaste({ sceneId: activeSceneId, componentsById: componentsByIdCp, isScreenRelativeMode, faceLandmark, screenAnchorGroupPosition });
			if (!parentId) continue;

			const dict = generateOldToNewIdDict({ componentToCopy, componentsById: componentsByIdCp, targetSceneId: activeSceneId });
			if (!dict) continue;
			const copy = copySpatialComponent({ spatialComponent: componentToCopy, oldToNewIdDict: dict });
			if (!copy) continue;
			const newRenderOrder = getHighestActiveRenderOrder(componentsByIdCp, activeSceneId, isScreenRelativeMode) + 1;
			const componentsByIdOut = insertSpatialComponent({
				spatialComponent: copy,
				parentId,
				sourceSceneIsAr,
				sourceSceneId,
				isFlatOrientation,
				componentsById: componentsByIdCp,
				offset,
				newTitle,
				newRenderOrder,
				adjustPositionToCamera: !isScreenRelativeMode
			});
			if (!componentsByIdCp) continue;
			componentsByIdCp = JSON.parse(JSON.stringify(componentsByIdOut));
		}

		doc.componentsById = JSON.parse(JSON.stringify(componentsByIdCp));
	});
	return {
		...state,
		contentDoc,
	};
};

const undoCnDoc = (state: IContentReducer): IContentReducer => {
	if (!cdsClient.canUndo()) return state;
	cdsClient.undo();
	return {
		...state,
		contentDoc: cdsClient.getContentDoc(),
	};
};

const redoCnDoc = (state: IContentReducer): IContentReducer => {
	if (!cdsClient.canRedo()) return state;
	cdsClient.redo();
	return {
		...state,
		contentDoc: cdsClient.getContentDoc(),
	};
};

const removeSceneReferences = (state: IContentReducer, action: IOnRemoveSceneReferences_Cn_Doc_Action): IContentReducer => {
	const { sceneIds } = action.payload;
	if (!sceneIds) return state;
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		for (let i = 0; i < sceneIds.length; i++) {
			const sceneId = sceneIds[i];
			const allComponentIds = Object.keys(doc.componentsById);
			// loop through all components in content doc doc
			for (let i = 0; i < allComponentIds.length; i++) {
				const compId = allComponentIds[i];
				const comp = doc.componentsById[compId] as ISpatialComponentUnion;
				// continue if not a spatial component or component has no actions to remove
				// otherwise delete action property
				if (isAbstractComponent(comp) || !comp.actions) continue;
				for (const triggerType in comp.actions) {
					const actionArray = comp.actions[triggerType as keyof typeof comp.actions];
					if (!actionArray) continue;
					for (let i = 0; i < actionArray.length; i++) {
						const action = actionArray[i];
						if (action.type === IActionCategory.linkScene && action.sceneId === sceneId) delete actionArray[i];
					}
				}
			}
		}
	});
	return {
		...state,
		contentDoc,
	};
};

const onSetMultipleComponentProps_Cn_Doc = (state: IContentReducer, action: IOnSetMultipleComponentProps_Cn_Doc_Action): IContentReducer => {
	const propArray = action.payload;
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		if (!doc.componentsById) doc.componentsById = {};
		for (let i = 0; i < propArray.length; i++) {
			const { id, ...rest } = propArray[i];

			const componentState = isAbstractComponentType(rest.type) ? rest : propArray[i];

			// console.log('REDUCER update fontsize', rest.fontSize)

			if (!doc.componentsById[id]) doc.componentsById[id] = {} as IComponentUnion;
			for (const k in componentState) {
				const key = k as keyof typeof componentState;
				const data = componentState[key];
				if (typeof data === 'object' && data !== null && !(data instanceof Array)) {
					doc.componentsById[id][key] = {
						...doc.componentsById[id][key],
						...data,
					};
					continue;
				}
				doc.componentsById[id][key] = JSON.parse(JSON.stringify(data)); // Close the object to prevent Automerge: "Cannot create a reference to an existing document object"
			}
		}
	});
	return {
		...state,
		contentDoc,
	};
};

const onRemoveComponentAction_Cn_Doc = (state: IContentReducer, action: IOnRemoveComponentAction_Cn_Doc_Action): IContentReducer => {
	const { entityId, triggerType, index } = action.payload;
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		const actions = (doc.componentsById[entityId] as ISpatialComponentUnion).actions!;
		actions[triggerType]!.splice(index, 1);
		if (actions[triggerType]?.length === 0) {
			// if no actions associated with trigger remove trigger from data structure
			delete actions![triggerType];
			// remove empty action object if necessary
			if (Object.keys(actions).length === 0) {
				delete (doc.componentsById[entityId] as ISpatialComponentUnion).actions;
			}
		}
		
	});
	return {
		...state,
		contentDoc,
	};
};

const onReparentComponent = (state: IContentReducer, action: IOnReparentComponent_Cn_Doc_Action): IContentReducer => {
	const { id, oldParentId, newParentId } = action.payload;

	const contentDoc = cdsClient.changeContentDoc((doc) => {
		const oldParent = doc.componentsById[oldParentId] as IAbstractComponentUnion;
		const newParent = doc.componentsById[newParentId] as IAbstractComponentUnion;

		oldParent.children = oldParent.children.filter((_id) => _id !== id);
		newParent.children.push(id);
	});
	return {
		...state,
		contentDoc,
	};
};

const onAddFaceLandmarkToScene = (state: IContentReducer, action: IOnAddFaceLandmarkToScene_Cn_Doc_Action): IContentReducer => {
	const { landmark, sceneId } = action.payload;

	const landmarkId = MathUtils.generateUUID();
	const faceLandmarkGroup = createFaceLandmarkGroup(landmark);
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		doc.componentsById[landmarkId] = faceLandmarkGroup;
		(doc.componentsById[sceneId] as ISceneComp).children.push(landmarkId);
	});

	return {
		...state,
		contentDoc,
	};
};

const onAddAnchorGroupToScene = (state: IContentReducer, action: IOnAddAnchorGroupToScene_Cn_Doc_Action): IContentReducer => {
	const { anchorPositionType, sceneId } = action.payload;
	const newId = MathUtils.generateUUID();
	const anchorGroup = createScreenAnchorGroup(anchorPositionType);
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		const sceneContentId = (doc.componentsById[sceneId] as ISceneComp).children.filter((id) => doc.componentsById[id].type === IComponentType.ScreenContent)[0];
		doc.componentsById[newId] = anchorGroup;
		(doc.componentsById[sceneContentId] as ISceneComp).children.push(newId);
	});

	return {
		...state,
		contentDoc,
	};
};

const onAddComponentAction_Cn_Doc = (state: IContentReducer, action: IOnAddComponentAction_Cn_Doc_Action): IContentReducer => {
	// Need entity, index, action
	const { entityId, actionData, triggerType } = action.payload;
	const actionDataStr = JSON.stringify(actionData);
	if (actionDataStr === '{}') Sentry.captureException(new Error('Empty action object'), {
		extra: { entityId, actionData, triggerType }
	})
	const actionDataCp = JSON.parse(actionDataStr) as ISpatialComponentActionData;
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		const entity = doc.componentsById[entityId] as ISpatialComponentUnion;
		if (entity.actions && entity.actions[triggerType]) {
			entity.actions[triggerType]?.push(actionDataCp);
		} else {
			if (!entity.actions) entity.actions = {}
			entity.actions[triggerType] = [actionDataCp];
		}
	});
	return {
		...state,
		contentDoc,
	};
};

const onSetComponentAction_Cn_Doc = (state: IContentReducer, action: IOnSetComponentAction_Cn_Doc_Action): IContentReducer => {
	const { entityId, index, triggerType, actionData } = action.payload;
	const actionDataCp = JSON.parse(JSON.stringify(actionData));
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		(doc.componentsById[entityId] as ISpatialComponentUnion).actions![triggerType]![index] = actionDataCp;
	});
	return {
		...state,
		contentDoc,
	};
};

const removeImageTracking_Cn_Doc = (state: IContentReducer, _action: IOnRemoveImageTracking_Cn_Doc_Action): IContentReducer => {
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		delete doc.tracking;
	});
	return {
		...state,
		contentDoc,
	};
};

const setWebEmbed = (state: IContentReducer, action: IOnSetWebEmbed_Cn_Doc_Action): IContentReducer => {
	if (!action.payload) return state;
	const currentWebEmbed = state?.contentDoc?.webEmbed ? JSON.parse(JSON.stringify(state?.contentDoc?.webEmbed)) : {};
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		doc.webEmbed = {
			...currentWebEmbed,
			...action.payload,
		};
	});
	return {
		...state,
		contentDoc,
	};
};

const setAnalyticsTracking_Cn_Doc = (state: IContentReducer, action: IOnSetAnalyticsTracking_Cn_Doc_Action): IContentReducer => {
	if (!action.payload) return state;
	const trackingDict = action.payload;
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		doc.analyticsTracking = {
			...trackingDict,
		};
	});
	return {
		...state,
		contentDoc,
	};
};

const clearScreenSceneContent = (state: IContentReducer, action: IOnClearScreenSceneContent_Cn_Doc_Action): IContentReducer => {
	const sceneId = action.payload;
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		const screenContentId = (doc.componentsById[sceneId] as ISceneComp).children.filter((id) => doc.componentsById[id].type == IComponentType.ScreenContent)[0];
		const anchorGroupIds = (doc.componentsById[screenContentId] as IScreenContent).children;
		for (let i = 0; i < anchorGroupIds.length; i++) {
			(doc.componentsById[anchorGroupIds[i]] as IScreenAnchorGroup).children = [];
		}
	});
	return {
		...state,
		contentDoc,
	};
};

const removeIdlePoseTime = (state: IContentReducer, action: IOnRemoveIdlePoseTime_Cn_Doc_Action): IContentReducer => {
	const entityId = action.payload.entityId;
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		if ((doc.componentsById[entityId] as IModel3d).idlePoseTime !== undefined) {
			delete (doc.componentsById[entityId] as IModel3d).idlePoseTime;
		}
	});
	return {
		...state,
		contentDoc,
	};
};

const removeIdleAnimation = (state: IContentReducer, action: IOnRemoveIdleAnimation_Cn_Doc_Action): IContentReducer => {
	const entityId = action.payload.entityId;
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		if ((doc.componentsById[entityId] as IModel3d).idleAnimation !== undefined) {
			delete (doc.componentsById[entityId] as IModel3d).idleAnimation;
		}
	});
	return {
		...state,
		contentDoc,
	};
};

const setProjectColor = (state: IContentReducer, action: IOnSetProjectColors_Cn_Doc_Action): IContentReducer => {
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		doc.projectColors = action.payload;
	});

	return {
		...state,
		contentDoc,
	};
};

const onSetEmitterProperties = (state: IContentReducer, action: IOnSetEmitterProperties_Cn_Doc_Action): IContentReducer => {
	const { entityId, settings } = action.payload;
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		const { emitter } = doc.componentsById[entityId] as IEmitter;
		for (const key in settings) {
			const setting = settings[key];
			if (typeof setting !== 'object' || setting === null) {
				emitter[key] = setting;
				continue;
			}
			if (!emitter[key]) emitter[key] = {};
			for (const valueKey in setting) {
				emitter[key][valueKey] = setting[valueKey];
			}
		}
	});
	return {
		...state,
		contentDoc,
	};
};

const onSetEmitterGroupProperties = (state: IContentReducer, action: IOnSetEmitterGroupProperties_Cn_Doc_Action): IContentReducer => {
	const { entityId, settings } = action.payload;
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		const { emitterGroup } = doc.componentsById[entityId] as IEmitter;
		for (const key in settings) {
			const setting = settings[key];
			if (typeof setting !== 'object') {
				emitterGroup[key] = setting;
				continue;
			}
			if (!emitterGroup[key]) emitterGroup[key] = {};
			for (const valueKey in setting) {
				emitterGroup[key][valueKey] = setting[valueKey];
			}
		}
	});
	return {
		...state,
		contentDoc,
	};
};

const onSetEmitterBaseProperties = (state: IContentReducer, action: IOnSetEmitterBaseProperties_Cn_Doc_Action): IContentReducer => {
	const { entityId, properties } = action.payload;
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		const emitter = doc.componentsById[entityId] as IEmitter;
		for (const key in properties) {
			emitter[key] = properties[key];
		}
	});
	return {
		...state,
		contentDoc,
	};
};

const setTargetImageReferenceObject = (state: IContentReducer, action: IOnSetTargetImageReferenceObject_Cn_Doc_Action): IContentReducer => {
	const { position, referenceObject } = action.payload;
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		const targetPos = position ?? state.contentDoc.targetImageReferenceObject?.position
		if (typeof targetPos === 'undefined') throw new Error('Targe position not defined')
		doc.targetImageReferenceObject = {
			referenceObject: referenceObject || state.contentDoc.targetImageReferenceObject?.referenceObject || ITargetImageReferenceObject.none,
			position: targetPos
		}
	})
	return { ...state, contentDoc }
};

const setInitialTemplateSlug = (state: IContentReducer, action: IOnSetInitialTemplateSlug_Cn_Doc_Action): IContentReducer => {
	const initialTemplateSlug = action.payload;
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		doc.initialTemplateSlug = initialTemplateSlug;
	});
	return {
		...state,
		contentDoc
	}
}

const onSetChromaKeyProperties = (state: IContentReducer, action: IOnSetChromaKeyProperties_Cn_Doc_Action): IContentReducer => {

	const contentDoc = cdsClient.changeContentDoc((doc) => {
		if (!(doc.componentsById[action.payload.entityId] as IVideo).chromaKey) {
			(doc.componentsById[action.payload.entityId] as IVideo).chromaKey = {};
			(doc.componentsById[action.payload.entityId] as IVideo).chromaKey!.color = [0, 1, 0];
			(doc.componentsById[action.payload.entityId] as IVideo).chromaKey!.smoothness = 0.08;
			(doc.componentsById[action.payload.entityId] as IVideo).chromaKey!.similarity = 0.05;
			(doc.componentsById[action.payload.entityId] as IVideo).chromaKey!.spill = 0.02;
		}
		if (typeof action.payload?.color != 'undefined') (doc.componentsById[action.payload.entityId] as IVideo).chromaKey!.color = action.payload.color == null ? null : [...action.payload.color];
		if (typeof action.payload?.smoothness != 'undefined') (doc.componentsById[action.payload.entityId] as IVideo).chromaKey!.smoothness = action.payload.smoothness;
		if (typeof action.payload?.similarity != 'undefined') (doc.componentsById[action.payload.entityId] as IVideo).chromaKey!.similarity = action.payload.similarity;
		if (typeof action.payload?.spill != 'undefined') (doc.componentsById[action.payload.entityId] as IVideo).chromaKey!.spill = action.payload.spill;
	});
		
	return {
		...state,
		contentDoc
	};
}

const onSetProjectShadows = (state: IContentReducer, action: IOnSetProjectShadowsEnabled_Cn_Doc_Action): IContentReducer => {
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		doc.shadowsEnabled = action.payload;
	});

	return {
		...state,
		contentDoc
	};
}

const onSetDevice = (state: IContentReducer, action: IOnSetDevice_Cn_Doc_Action): IContentReducer => {
	console.log(cdsClient)
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		if (!!action.payload) doc.device = action.payload;
		else if (doc.device) delete doc.device;
	});

	return {
		...state,
		contentDoc
	};
}

const onSetActionTrigger = (state: IContentReducer, action: IOnSetActionTrigger_Cn_Doc_Action): IContentReducer => {
	const { componentId, oldTrigger, newTrigger, actionIndex } = action.payload;
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		const component = doc.componentsById[componentId] as ISpatialComponentUnion | ISceneComp;
		const oldActionsCp = JSON.parse(JSON.stringify(component.actions?.[oldTrigger]));
		const slicedAction = oldActionsCp?.splice(actionIndex, 1);
		if (!component.actions![newTrigger]) {
			component.actions![newTrigger] = []
		}
		component.actions![newTrigger]!.push(slicedAction![0])
		if (oldActionsCp.length > 0) {
			component.actions![oldTrigger] = oldActionsCp;
		} else if (component.actions) {
			delete component.actions[oldTrigger];
		} 
	});

	return {
		...state,
		contentDoc
	}
}

const onSetDisableHlsVideoStreaming = (state: IContentReducer, action: IOnSetDisableHLSvideoStreaming_Cn_Doc_Action): IContentReducer => {
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		const {sceneId, hlsDisabled} = action.payload;
		(doc.componentsById[sceneId] as ISceneComp).hlsDisabled = hlsDisabled;
	});

	return {
		...state,
		contentDoc
	}
}

const onSetScene360Background = (state: IContentReducer, action: IOnSetScene360Background_Cn_Doc_Action): IContentReducer => {
	const contentDoc = cdsClient.changeContentDoc((doc) => {
        const scene = doc.componentsById[action.payload.sceneId] as ISceneComp;
		if (scene.trackingType !== ITrackingTypes.content360) return state;
		const { filestoreId = scene.content360?.filestoreId, title = scene.content360?.title, muted, loop, mediaType } = action.payload;
		
        if (filestoreId === null) {
			scene.content360 = null;
			return;
		}

        const currentContent = JSON.parse(JSON.stringify(scene.content360 || {})) as IImageBackground360 | IVideoBackground360;
        if (mediaType === 'image') {
            scene.content360 = {
                ...currentContent,
                filestoreId,
                type: 'image',
                title
            } as IImageBackground360
        } else {
            scene.content360 = {
                ...currentContent,
                filestoreId,
                type: 'video',
                title,
                loop: Boolean(loop),
                muted: Boolean(muted)
            } as IVideoBackground360
        }
	})
	
	return { ...state, contentDoc }
}

const onSetScene360CameraRotation = (state: IContentReducer, action: IOnSetScene360InitialCameraRotation_Cn_Doc_Action): IContentReducer => {

	const contentDoc = cdsClient.changeContentDoc((doc) => {
		const { sceneId, rotation } = action.payload;
		const scene = doc.componentsById[sceneId] as ISceneComp;
		if (scene.trackingType !== ITrackingTypes.content360 || !scene.content360) return state;
		
		scene.content360.initialCameraRotation = rotation
	});

	return {
		...state,
		contentDoc
	}
}

const onSetVideoRenditionProgress = (state: IContentReducer, action: IOnSetVideoRenditionProgressData_Cn_Doc_Action): IContentReducer => {
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		const {id, ...rest} = action.payload;
		if (!doc.videoStatusDict) doc.videoStatusDict = {};
		
		if (Object.keys(rest).length === 0) {
			delete(doc.videoStatusDict[id])
			return;
		}

		const { progressUrl, status } = rest as any;
		doc.videoStatusDict[id] = { progressUrl, status }
	});

	return {
		...state,
		contentDoc
	}
}

const onSetLauchScreenField = (state: IContentReducer, action: IonSetLaunchScreenField_Cn_Doc_Action): IContentReducer => {
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		if (typeof doc.launchScreen === 'undefined') {
			doc.launchScreen = DEFAULT_LAUNCH_SCREEN;
		}
		doc.launchScreen.fields[action.payload.fieldId] = action.payload.field
	})

	return {
		...state,
		contentDoc
	}
}

const onSetLauchScreenId = (state: IContentReducer, action: IonSetLaunchScreenId_Cn_Doc_Action): IContentReducer => {
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		if (typeof doc.launchScreen === 'undefined') {
			doc.launchScreen = DEFAULT_LAUNCH_SCREEN;
		}
		doc.launchScreen.id = action.payload.id
		doc.launchScreen.title = action.payload.id
	})

	return { ...state, contentDoc }
}

const onAddMediaFile = (state: IContentReducer, action: IOnAddMediaFile_Cn_Doc_Action): IContentReducer => {
	const contentDoc = cdsClient.changeContentDoc((doc) => {
		if (typeof doc?.mediaFilesById === 'undefined') {
			doc['mediaFilesById'] = {}
		}
		doc.mediaFilesById[action.payload.id] = action.payload
	})

	return { ...state, contentDoc }
}

const onSetGrabnGoOptions = (state: IContentReducer, action: IOnSetGrabnGoOptions_Cn_Doc_Action): IContentReducer => {
	const contentDoc = cdsClient.changeContentDoc((doc) => {	
		if (!doc.tracking) doc.tracking = {};
		if (!doc.tracking?.grabAndGo) doc.tracking.grabAndGo = {};
		doc.tracking.grabAndGo = {
			...doc.tracking.grabAndGo,
			...action.payload
		}
	})

	return { ...state, contentDoc }
}


// REFACTOR No real solution for typing the action here, an Action union is considered an anti-pattern ( https://redux.js.org/usage/usage-with-typescript#avoid-action-type-unions ) and moving to Redux Toolkit is a much larger job
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const contentReducer = (state: IContentReducer = initialState, action: any) => {
	switch (action.type) {
		case IContentDocActionTypes.SET_INITIAL_TEMPLATE_SLUG:
			return setInitialTemplateSlug(state, action);
		case IContentDocActionTypes.SET_TARGET_IMAGE_REFERENCE_OBJECT_CN_DOC:
			return setTargetImageReferenceObject(state, action);
		case IContentDocActionTypes.ADD_PROJECT_COLORS_CN_DOC:
			return setProjectColor(state, action);
		case IContentDocActionTypes.REMOVE_IDLE_POSE_TIME:
			return removeIdlePoseTime(state, action);
		case IContentDocActionTypes.REMOVE_IDLE_ANIMATION:
			return removeIdleAnimation(state, action);
		case IContentDocActionTypes.SET_ANALYTICS_TRACKING:
			return setAnalyticsTracking_Cn_Doc(state, action);
		case IContentDocActionTypes.CLEAR_SCREEN_SCENE_CONTENT:
			return clearScreenSceneContent(state, action);
		case IContentDocActionTypes.COPY_SCREEN_SCENE_CONTENT:
			return copyScreenSceneContent(state, action);
		case IContentDocActionTypes.SET_WEB_EMBED_CN_DOC:
			return setWebEmbed(state, action);
		case IContentDocActionTypes.REMOVE_IMAGE_TRACKING_CN_DOC:
			return removeImageTracking_Cn_Doc(state, action);
		case IContentDocActionTypes.UNDO_CN_DOC:
			return undoCnDoc(state);
		case IContentDocActionTypes.REDO_CN_DOC:
			return redoCnDoc(state);
		case IContentDocActionTypes.REPLACE_ENTITY_CN_DOC:
			return replaceEntity(state, action);
		case IContentDocActionTypes.ADD_ENTITY_CN_DOC:
			return addEntityContentDoc(state, action);
		case IContentDocActionTypes.REMOVE_ENTITIES_CN_DOC:
			return removeComponentsFromContentDoc(state, action);
		case IContentDocActionTypes.SET_ENTITY_POSITIONS_CN_DOC:
			return setPositionsInContentDoc(state, action);
		case IContentDocActionTypes.SET_ENTITY_ROTATIONS_CN_DOC:
			return setRotationsInContentDoc(state, action);
		case IContentDocActionTypes.SET_SCALE_FACTORS_CN_DOC:
			return setScalesInContentDoc(state, action);
		case IContentDocActionTypes.SET_ENTITY_COLORS_CN_DOC:
			return setRgbaColorsInContentDoc(state, action);
		case IContentDocActionTypes.CHANGE_CN_DOC_REDUX:
			return updateContentDocFromBackend(state, action);
		case IContentDocActionTypes.PASTE_COPIED_ENTITIES_CN_DOC:
			return pasteCopiedEntitiesInContentDoc(state, action);
		case IContentDocActionTypes.COPY_SCENES_AT_INDEX_CN_DOC:
			return copyScenesAtIndex_Cn_Doc(state, action);
		case IContentDocActionTypes.SET_COMPONENT_TRANSITION_CN_DOC:
			return setComponentTransition_Cn_Doc(state, action);
		case IContentDocActionTypes.SET_MULTIPLE_COMPONENT_PROPS_CN_DOC:
			return onSetMultipleComponentProps_Cn_Doc(state, action);
		case IContentDocActionTypes.REMOVE_SCENE_REFERENCES_CN_DOC:
			return removeSceneReferences(state, action);
		case IContentDocActionTypes.ADD_IMAGE_TRACKING_CN_DOC:
			return addImageTracking_Cn_Doc(state, action);
		case IContentDocActionTypes.SET_ACTIVE_SCENE_CHILDREN:
			return setActiveSceneChildren(state, action);
		case IContentDocActionTypes.SET_ROOT_CHILDREN:
			return onSetRootChildren(state, action);
		case IContentDocActionTypes.ADD_COMPONENT_ACTION_CN_DOC:
			return onAddComponentAction_Cn_Doc(state, action);
		case IContentDocActionTypes.REMOVE_COMPONENT_ACTION_CN_DOC:
			return onRemoveComponentAction_Cn_Doc(state, action);
		case IContentDocActionTypes.SET_COMPONENT_ACTION_CN_DOC:
			return onSetComponentAction_Cn_Doc(state, action);
		case IContentDocActionTypes.REPARENT_COMPONENT_CN_DOC:
			return onReparentComponent(state, action);
		case IContentDocActionTypes.ADD_FACE_LANDMARK_TO_SCENE_CN_DOC:
			return onAddFaceLandmarkToScene(state, action);
		case IContentDocActionTypes.ADD_ANCHOR_GROUP_TO_SCENE_CN_DOC:
			return onAddAnchorGroupToScene(state, action);
		case IContentDocActionTypes.SET_EMITTER_PROPERTIES_CN_DOC:
			return onSetEmitterProperties(state, action);
		case IContentDocActionTypes.SET_EMITTER_GROUP_PROPERTIES_CN_DOC:
			return onSetEmitterGroupProperties(state, action);
		case IContentDocActionTypes.SET_EMITTER_BASE_PROPERTIES_CN_DOC:
			return onSetEmitterBaseProperties(state, action);
		case IContentDocActionTypes.SET_CHROMA_KEY_PROPERTIES_CN_DOC:
			return onSetChromaKeyProperties(state, action);
		case IContentDocActionTypes.SET_PROJECT_SHADOWS_ENABLED_CN_DOC:
			return onSetProjectShadows(state, action);
		case IContentDocActionTypes.SET_DEVICE_CN_DOC:
			return onSetDevice(state, action);
		case IContentDocActionTypes.SET_ACTION_TRIGGER_CN_DOC:
			return onSetActionTrigger(state, action);
		case IContentDocActionTypes.SET_DISABLE_HLS_VIDEO_STREAMING_CN_DOC:
			return onSetDisableHlsVideoStreaming(state, action);
		case IContentDocActionTypes.SET_SCENE_360_BACKGROUND: 
			return onSetScene360Background(state, action);
		case IContentDocActionTypes.SET_SCENE_360_INITIAL_CAMERA_ROTATION:
			return onSetScene360CameraRotation(state, action);
		case IContentDocActionTypes.SET_VIDEO_RENDITION_PROGRESS_DATA:
			return onSetVideoRenditionProgress(state, action);
		case IContentDocActionTypes.SET_LAUNCH_SCREEN_FIELD:
			return onSetLauchScreenField(state, action);
		case IContentDocActionTypes.SET_LAUNCH_SCREEN_ID:
			return onSetLauchScreenId(state, action);
		case IContentDocActionTypes.ADD_MEDIA_FILE:
			return onAddMediaFile(state, action);
		case IContentDocActionTypes.SET_GRAB_N_GO_OPTIONS:
			return onSetGrabnGoOptions(state, action);
		default:
			return state;
	}
};

export default contentReducer;
