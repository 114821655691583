import { store } from '@/store';
import { FILESTORE_URL } from '@/settings';
import { onAddMediaFile_Cn_Doc } from '@/store/actions';
import { CDSClient } from '@/sync/cds';
import { IFsFileData, IFsFileType } from '@r3f-component-data-structure';
import { FsAudio, FsExternalFile, FsFile, FsImage, FsImage360, FsImageTarget, FsVideo, FsVideo360, zmlToExternalFile } from './files';
import { FsModel3D } from './files/model3d';
import { zwClient } from '@/sync/zapworks';
import { IFileType } from '@zml-client';

export class Filestore {
    private _cds: CDSClient | undefined
    private _jwt: string | undefined
    private _projectId: string | undefined
    private _files: {[id: string]: FsFile} = {}
    private _cacheBlobs: {[id: string]: Blob} = {}
    private _cacheUrls: {[id: string]: string} = {}
    private _externalFilesById: { [id: string]: FsExternalFile } = {}
    private _cacheLatestAudio: { date: number, files: FsExternalFile[] } | undefined
    private _cache360Files: { date: number, files: FsExternalFile[] } | undefined

    constructor() {
    }

    async init(cds: CDSClient, projectId: string) {
        this._cds = cds
        this._projectId = projectId
        const req = await this._cds.request(`${this._cds.getProjectUrl()}/filestore/jwt/`)
        let response: undefined | { token: string }
        try {
            response = await req.json()
        } catch(err) { console.error(err) }
        this._jwt = response?.token
        this._files = {}
        this._cacheBlobs = {}
        this._cacheUrls = {}
        this._externalFilesById = {}
    }

    save(fl: Blob, type: IFsFileType) {
        console.log('Filestore:save')
        const id = crypto.randomUUID()
        // TODO:
        //   1. get a jwt from CDS
        //   2. upload file to the store (S3)
        //   3. create a new media file object (based on the type)
        //   4. save to content doc
    }

    async importFromZml(zmlFileId: string) {
        const importResponse = await this._requestImportFromZml(zmlFileId)
        if (typeof importResponse === 'undefined') {
            console.error('Unable to import file from ZML:', zmlFileId)
            return
        }

        const fsFile = this._create(importResponse)
        store.dispatch(onAddMediaFile_Cn_Doc({ id: fsFile.id, type: fsFile.type, name: fsFile.name }))
        return fsFile
    }

    load<T extends FsFile>(fileId?: string) {
        if (typeof fileId === 'undefined') throw `Filestore: Unable to load undefined file ID`
        if (typeof this._files[fileId] !== 'undefined') return this._files[fileId] as T
        const filesById = store.getState().contentReducer.contentDoc.mediaFilesById
        const mediaFile = filesById[fileId]
        if (typeof mediaFile === 'undefined') throw `Filestore: File ${fileId} not found in content doc`
        this._files[fileId] = this._create<T>(mediaFile)
        return this._files[fileId] as T
    }

    private _create<T extends FsFile>(data: IFsFileData): T {
        if (typeof this._projectId === 'undefined') throw 'Filestore: Project ID is undefined'
        switch (data.type) {
            case IFsFileType.Image:
                return (new FsImage(data.id, this._projectId, data.name) as T)
            case IFsFileType.Model3d:
                return (new FsModel3D(data.id, this._projectId, data.name) as T)
            case IFsFileType.Video:
                return (new FsVideo(data.id, this._projectId, data.name) as T)
            case IFsFileType.Audio:
                return (new FsAudio(data.id, this._projectId, data.name) as T)
            case IFsFileType.Image360:
                return (new FsImage360(data.id, this._projectId, data.name) as T)
            case IFsFileType.Video360:
                return (new FsVideo360(data.id, this._projectId, data.name) as T)
            case IFsFileType.ImageTarget:
                return (new FsImageTarget(data.id, this._projectId, data.name) as T)
            default:
                throw `Filestore: Unknown media file type ${data.type}`
        }
    }

    async getFileBlob(url: string) {
        if (typeof this._cacheBlobs[url] !== 'undefined') return this._cacheBlobs[url]
        if (typeof this._jwt === 'undefined') throw 'Filestore: JWT is undefined'
        const req = await fetch(url, { headers: [['X-Authorization', this._jwt]] })
        if (!req.ok) throw new Error(`Filestore: Unable to download file ${req.status} ${url}`)
        this._cacheBlobs[url] = await req.blob()
        return this._cacheBlobs[url]
    }

    getObjectUrl(id: string, blob: Blob | File) {
        if (typeof this._cacheUrls[id] !== 'undefined') return this._cacheUrls[id]
        if (blob.size === 0 || blob.type === '') console.warn('Filestore: Empty file', id)
        this._cacheUrls[id] = URL.createObjectURL(blob)
        return this._cacheUrls[id]
    }

    private async _requestImportFromZml(zmlFileId: string) {
        if (typeof this._cds === 'undefined') throw 'Filestore: CDS is undefined'
        const req = await this._cds.request(`${this._cds.getProjectUrl()}/filestore/zml-import/${zmlFileId}/`)
        let response: undefined | FsFile
        try {
            response = await req.json()
        } catch(err) {}
        return response
    }

    async latestAudioFromZml(limit = 5) {
        if (typeof this._cacheLatestAudio === 'undefined' || Date.now() - this._cacheLatestAudio.date > 10000) {
            const resp = await zwClient.zml.search<IFileType.Audio>([IFileType.Audio], 1, undefined, limit)
            this._cacheLatestAudio = { date: Date.now(), files: resp.results.map(zmlToExternalFile) }
        }
        return this._cacheLatestAudio.files
    }

    clearAudioCache() {
        this._cacheLatestAudio = undefined
    }

    async latest360FilesFromZml(limit = 5) {
        if (typeof this._cache360Files === 'undefined' || Date.now() - this._cache360Files.date > 10000) {
            const resp = await zwClient.zml.search<IFileType.Image360 | IFileType.Video360>([IFileType.Image360, IFileType.Video360], 1, undefined, limit)
            this._cache360Files = { date: Date.now(), files: resp.results.map(zmlToExternalFile) }
        }
        return this._cache360Files.files
    }

    clear360FilesCache() {
        this._cache360Files = undefined
    }

    async saveImageTarget(imageFile: File, zptBinary: ArrayBuffer, name: string) {
        if (typeof this._projectId === 'undefined') throw 'Filestore: Project ID is undefined'
        if (typeof this._jwt === 'undefined') throw 'Filestore: JWT is undefined'

        const zptFileHeader = new Uint8Array(32)
        zptFileHeader.set(new TextEncoder().encode(`ZPT+IMG${zptBinary.byteLength};${imageFile.type}`))
        const concatZpt = new Uint8Array(zptFileHeader.byteLength + zptBinary.byteLength + imageFile.size)
        concatZpt.set(zptFileHeader, 0)
        const zptByteArray = new Uint8Array(zptBinary)
        concatZpt.set(zptByteArray, zptFileHeader.byteLength)
        concatZpt.set(new Uint8Array(await imageFile.arrayBuffer()), zptFileHeader.byteLength + zptByteArray.byteLength)

        const id = crypto.randomUUID()
        const url = `${FILESTORE_URL}/proj-${this._projectId}/${id}`;
        const reqHeaders = { 'X-Authorization': this._jwt };

        await Promise.all([
            await fetch(`${url}/img`, { headers: reqHeaders, method: 'PUT', body: imageFile }),
            await fetch(`${url}/zpt`, { headers: reqHeaders, method: 'PUT', body: concatZpt })
        ])

        this._cacheBlobs[`${url}/img`] = imageFile
        store.dispatch(onAddMediaFile_Cn_Doc({ id, type: IFsFileType.ImageTarget, name }))
        return new FsImageTarget(id, this._projectId, name)
    }

    loadExternalFile(id: string) {
        return this._externalFilesById[id]
    }

    saveExternalFile(id: string, file: FsExternalFile) {
        this._externalFilesById[id] = file
    }
}

// Singletone
export const filestore = new Filestore()
