import { ThreeEvent } from '@react-three/fiber';
import React from 'react';
import { useDispatch } from 'react-redux';
import { onAddToast } from '../../../../store/actions';
import { ToastsData } from '../../../../utils/toasts-data';
import { ITuple3 } from '../../r3f-components/component-data-structure';
import { EntityErrorBoundary } from '../../r3f-components/components/boundaries';
import EntitySuspenseBoundary from '../../r3f-components/components/boundaries/EntitySuspense';

interface IParentProps {
	position: ITuple3;
	rotation: ITuple3;
	scale: ITuple3;
	renderOrder: number;
	label?: string;
    forceLoading?: boolean;
    forceError?: boolean;
	onPointerDown?: (e: ThreeEvent<PointerEvent>) => unknown;
	onPointerUp?: (e: ThreeEvent<PointerEvent>) => unknown;
	children: JSX.Element | JSX.Element[];
	floatingInput?: JSX.Element;
}

const EntityBoundaries: React.FunctionComponent<IParentProps> = ({ forceError = false, forceLoading = false, rotation, scale, position, floatingInput, renderOrder, label = '', onPointerDown, onPointerUp, children }) => {
	const dispatch = useDispatch();

	return (
		<EntityErrorBoundary
			rotation={rotation}
			scale={scale}
			position={position}
			renderOrder={renderOrder}
            forceError={forceError}
			onPointerDown={onPointerDown}
			onPointerUp={(e) => {        
				onPointerUp?.(e);
			}}
			onError={() => dispatch(onAddToast(ToastsData.FailureLoadingError))}
		>
			<EntitySuspenseBoundary 
                rotation={rotation} 
                scale={scale} 
                position={position} 
                renderOrder={renderOrder}
                forceLoading={forceLoading}
            >
				{/* <group onPointerUp={onPointerUp} name={label}> */}
				<group name={label}>
					{children}
				</group>
			</EntitySuspenseBoundary>
			{floatingInput || null}
		</EntityErrorBoundary>
	);
};

export default EntityBoundaries;
