import { IDesignerState } from '@/../typings';
import { filestore, FsImage } from '@/filestore';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import SelectedEntityPositionHotspot from '../Hotspots/SelectedEntityPositionHotspot';
import { IImage, IImageReactProps, ITuple3 } from '../r3f-components/component-data-structure';
import EntityBoundaries from './EntityBoundaries/EntityBoundaries';
import { useGetHocBorderProperties, useGetHocCurvedProperties, useGetHocSpatialProperties, useHocPointerDown, useHocPointerMove, useHocPointerUp } from './hocCustomHooks';
import { getEntityRenderOrder } from './hocUtils';

interface IImageWrapped {
	id: string;
	enabled?: boolean;
}

const withImageBehaviour = (WrappedImage: FunctionComponent<IImageReactProps>): FunctionComponent<IImageWrapped> => {
	const FuncComp: FunctionComponent<IImageWrapped> = ({ enabled: entityIsEnabled = true, id }) => {
		// Selectors
		const entity = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById[id] as IImage);
		const projectShadowsEnabled = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.shadowsEnabled ?? true);

		// Refs
		const pointerStageRef = useRef<'down' | 'move' | 'up' | null>(null);
		const shiftLockedRef = useRef<number | null>(null);
		const initialPosRef = useRef<ITuple3 | null>(null); // Used to calculate pointer offset in pointerMove
		const previousPositionRef = useRef<ITuple3 | null>(null); // The previous position, used to compare with the current position
		const pointerUpHasRunRef = useRef<boolean | null>(null); // To prevent multiple dispatched actions when pointerUp runs twice this is reset onPointerMove
		const [showEntityPositionHotspot, setShowEntityPositionHotspot] = useState(false);

		// Entity properties to pass to wrapped component
		const renderOrder = getEntityRenderOrder(entity);
		const { opacity, hasAlpha, castShadow = false, receiveShadow = false } = entity;
		const { scale, position, rotation } = useGetHocSpatialProperties(entity);
		const { borderWidth, borderRadius, borderRgba } = useGetHocBorderProperties(entity);
		const { curvature } = useGetHocCurvedProperties(entity);
        const [imageUrl, setImageUrl] = useState<string | undefined>()
        const [imageHasError, setImageHasError] = useState(false);

		// Pointer events
		const onPointerDown = useHocPointerDown({ pointerStageRef, initialPosRef, entity, setShowEntityPositionHotspot });
		const onPointerMove = useHocPointerMove({ pointerStageRef, initialPosRef, pointerUpHasRunRef, previousPositionRef, entity, shiftLockedRef });
		const onPointerUp = useHocPointerUp({ pointerStageRef, pointerUpHasRunRef, initialPosRef, entity, setShowEntityPositionHotspot, shiftLockedRef });

		useEffect(() => {
			const fsImage = filestore.load<FsImage>(entity.filestoreId)
			fsImage.getUrl()
                .then(url => setImageUrl(url))
                .catch(() => setImageHasError(true))
		}, [entity.filestoreId])

		return (
			<>
				<EntityBoundaries
                    forceError={imageHasError}
                    forceLoading={typeof imageUrl === 'undefined'}
					position={position}
					rotation={rotation}
					scale={scale}
					renderOrder={renderOrder}
					onPointerDown={entityIsEnabled ? onPointerDown : undefined}
					onPointerUp={onPointerUp}
					label={`Image component ${id} render order ${renderOrder}`}
				>
					<WrappedImage
						id={id}
						scale={scale}
						rotation={rotation}
						position={position}
						imageUrl={imageUrl!}
						renderOrder={renderOrder}
						opacity={opacity}
						hasAlpha={hasAlpha}
						borderWidth={borderWidth}
						borderRgba={borderRgba}
						borderRadius={borderRadius}
						curvature={curvature}
						castShadow={castShadow && projectShadowsEnabled}
						receiveShadow={receiveShadow && projectShadowsEnabled}
						onPointerDown={entityIsEnabled ? onPointerDown : undefined}
						onPointerUp={entityIsEnabled ? onPointerUp : undefined}
					/>
				</EntityBoundaries>
				{showEntityPositionHotspot && <SelectedEntityPositionHotspot onPointerMove={showEntityPositionHotspot ? onPointerMove : undefined} onPointerUp={entityIsEnabled ? onPointerUp : undefined} />}
			</>
		);
	};
	return FuncComp;
};

export default withImageBehaviour;