import { useMemo, useState } from "react";
import { AnimationClip, Mesh, Object3D } from "three";
import * as utils from "three/examples/jsm/utils/SkeletonUtils";
import { createModelLoadPromise, modelCache } from "../components/Model3d/hooks";


interface IGLTFLoaderOptions {
    castShadow?: boolean;
    receiveShadow?: boolean;
}

export const useGLTFLoader = (url: string, options?: IGLTFLoaderOptions): {
    scene: Object3D;
    animations: AnimationClip[];
    skinnedMeshUuidToMesh: { [id: string]: Mesh };
} => {
    const { castShadow, receiveShadow } = options ?? {};

    // state used to trigger rerender in order to throw async errors into render phase
    const [_, triggerRender] = useState(0);


    const modelData = modelCache[url];

    // return model immediately if in cache
    if (modelData?.status === 'complete') {
        const {
            scene: modelScene,
            meshToSkinnedMeshUuidDict,
            animations
        } = modelData.parsedData;

        return useMemo(() => {
            // use Skeleton utils to safely clone skinned mesh (and other) models
            const clonedScene = (utils as any).clone(modelScene) as Object3D;
            const oldToNewUuidDict: { [id: string]: string } = {};

            // add render order to mesh if material(s) are transparent
            clonedScene.traverse((node: any) => {
                if (node.isSkinnedMesh || node.isMesh) {
                    // map old uuid to new uuids of clone
                    oldToNewUuidDict[node.userData.origUuid] = node.uuid;
                    if (castShadow) node.castShadow = true;
                    if (receiveShadow) node.receiveShadow = true;
                }
            });

            // fill skinnedMeshUUidToMesh dict to map source skinned mesh uuids to derived meshes
            // this is needed to update meshes with skinned mesh morph attribues during animations
            const skinnedMeshUuidToMesh: { [id: string]: Mesh } = {};
            for (const meshUuid in meshToSkinnedMeshUuidDict) {
                const newMeshUuid = oldToNewUuidDict[meshUuid];
                const oldSkinnedMeshUuid = meshToSkinnedMeshUuidDict[meshUuid];
                const newSkinnedMeshUuid = oldToNewUuidDict[oldSkinnedMeshUuid];
                const mesh = clonedScene.getObjectByProperty("uuid", newMeshUuid) as Mesh;
                if (newSkinnedMeshUuid) skinnedMeshUuidToMesh[newSkinnedMeshUuid] = mesh;
            }

            return { scene: clonedScene, animations, skinnedMeshUuidToMesh }
        }, [url, modelScene, animations, castShadow, receiveShadow])

    }

    // if async error throw error into render phase (triggered via triggerRender)
    if (modelData?.status === 'error') {
        throw modelData.error;
        // delete globalErrorCache[url];
        // throw error;
    }

    // throw promise for suspense to catch and also trigger re-render once error
    // occurs to throw async error in new render phase
    throw createModelLoadPromise(url, () => {
        console.log("prelading error");
        triggerRender((prevState) => prevState + 1);
    });
};
