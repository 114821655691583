import { useMemo, useState } from "react";
import { AnimationClip, FileLoader, Object3D } from "three";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import * as utils from "three/examples/jsm/utils/SkeletonUtils";
import { gltfLoader, IGltfParsedData } from "@/filestore";
import { convertGltf } from "../../utils/convertGltf";

type IPendingData = {
    status: 'pending';
    promise: Promise<any>;
}

type ICompleteData = {
    status: 'complete';
    gltf: GLTF;
    parsedData: IGltfParsedData;
    arrayBuffer: ArrayBuffer;
}

type IErrorData = {
    status: 'error';
    error: Error;
}

// global cache outside of react
export const modelCache: {
    [url: string]: IPendingData | ICompleteData | IErrorData
} = {};

// fill the global model cache or model error cache if not yet filled for url

export interface IPreloadModelReturnData {
    url: string;
    arrayBuffer: ArrayBuffer;
}

export const createModelLoadPromise = (
    url: string,
    onError?: () => any
): Promise<IPreloadModelReturnData> => {

    const modelData = modelCache[url];

    if (modelData?.status === 'pending') return modelData.promise;
    if (modelData?.status === 'complete') return Promise.resolve({ url, arrayBuffer: modelData?.arrayBuffer });

    // we are using the custom gltfloader which always uses textureLoader instead of 
    // the imageBitmapLoader. Latter does not always release memory coorectly leading resulting in app crashing
    // (see https://discourse.threejs.org/t/error-with-the-gltfloader-unable-to-load-texture-blob/32465)
    const loadPromise = new Promise<IPreloadModelReturnData>(res => {
        new FileLoader()
            .setResponseType('arraybuffer')
            .load(url, arrayBuffer => {
                if (typeof arrayBuffer === 'string') {
                    modelCache[url] = {
                        status: 'error',
                        error: new Error("model load error")
                    }
                    onError?.();
                    return;
                }

                gltfLoader.parse(arrayBuffer, '', gltf => {
                    if (modelCache[url]?.status !== 'complete') {
                        modelCache[url] = {
                            status: 'complete',
                            gltf,
                            parsedData: convertGltf(gltf),
                            arrayBuffer
                        };
                    }
                    res({ arrayBuffer, url });
                }, () => {
                    modelCache[url] = {
                        status: 'error',
                        error: new Error("model load error")
                    }
                    onError?.();
                }
                )
            },
                undefined,
                () => {
                    modelCache[url] = {
                        status: 'error',
                        error: new Error("model load error")
                    }
                    onError?.();
                }
            );
    });
    modelCache[url] = {
        status: 'pending',
        promise: loadPromise
    }
    return loadPromise;
};

export const preloadGltf = (urls: string[]): Promise<IPreloadModelReturnData[] | null> => {
    // create array of promises
    const promises: Promise<IPreloadModelReturnData>[] = [];

    for (let i = 0; i < urls.length; i++) {
        const url = urls[i];
        if (!!modelCache[url]?.status) continue;
        const modelLoadPromise = createModelLoadPromise(url);
        promises.push(modelLoadPromise);
    }
    return promises.length
        ? Promise.all([...promises])
        : new Promise((res) => res(null));
};

interface IGLTFLoaderOptions {
    castShadow?: boolean;
    receiveShadow?: boolean;
}

export const useGLTFArrayBufferLoader = (url: string, options?: IGLTFLoaderOptions): {
    scene: Object3D;
    animations: AnimationClip[];
    arrayBuffer: ArrayBuffer;
    isSkinned: boolean;
} => {
    const { castShadow, receiveShadow } = options ?? {};

    // state used to trigger rerender in order to throw async errors into render phase
    const [_, triggerRender] = useState(0);


    const modelData = modelCache[url];

    // return model immediately if in cache
    if (modelData?.status === 'complete') {
        const {
            scene: modelScene,
            animations,
        } = modelData.gltf;

        return useMemo(() => {
            // use Skeleton utils to safely clone skinned mesh (and other) models
            const clonedScene = (utils as any).clone(modelScene) as Object3D;
            let isSkinned = false;

            // add render order to mesh if material(s) are transparent
            clonedScene.traverse((node: any) => {
                if (node.isSkinnedMesh || node.isMesh) {
                    if (node.isSkinnedMesh) isSkinned = true;
                    if (castShadow) node.castShadow = true;
                    if (receiveShadow) node.receiveShadow = true;
                }
            });

            clonedScene.updateMatrixWorld(true);

            return { scene: clonedScene, animations, isSkinned, arrayBuffer: modelData.arrayBuffer }
        }, [url, modelScene, animations, castShadow, receiveShadow])

    }

    // if async error throw error into render phase (triggered via triggerRender)
    if (modelData?.status === 'error') {
        throw modelData.error;
        // delete globalErrorCache[url];
        // throw error;
    }

    // throw promise for suspense to catch and also trigger re-render once error
    // occurs to throw async error in new render phase
    throw createModelLoadPromise(url, () => {
        console.log("prelading error");
        triggerRender((prevState) => prevState + 1);
    });
};
