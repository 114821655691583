import { AnimationClip, Object3D } from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import * as Sentry from '@sentry/react';
import { IFsFileType } from "@/components/r3f/r3f-components/component-data-structure";
import { convertGltf } from '@/components/r3f/r3f-components/utils/convertGltf';
import { gltfLoader } from '../utils';
import { FsBaseFile } from "./base";

export interface IGltfParsedData {
    scene: Object3D
    animations: AnimationClip[]
    meshToSkinnedMeshUuidDict: { [id: string]: string }
}

export interface IGltfData {
    parsedData: IGltfParsedData
    gltf: GLTF
}

export class FsModel3D extends FsBaseFile {
    type: IFsFileType.Model3d = IFsFileType.Model3d
    private _cdnModelUrl: string
    private _cdnThumbnailUrl: string
    private _parsedData: IGltfData | undefined

    constructor(fileId: string, projectId: string, name: string) {
        super(fileId, projectId, name)
        const modelUrl = new URL(this.baseUrl)
        modelUrl.pathname = `${modelUrl.pathname}/model.glb`
        this._cdnModelUrl = modelUrl.toString()
        const thumbnailUrl = new URL(this.baseUrl)
        thumbnailUrl.pathname = `${thumbnailUrl.pathname}/frame0.png`
        this._cdnThumbnailUrl = thumbnailUrl.toString()
    }

    async getUrl() {
        return this.fs.getObjectUrl(this._cdnModelUrl, await this.getBlob())
    }

    async getBlob() {
        const blob = await this.fs.getFileBlob(this._cdnModelUrl)
        return blob
    }

    async getArrayBuffer() {
        const b = await (await this.getBlob()).arrayBuffer()
        return b
    }

    async getModelData(): Promise<IGltfData> {
        if (typeof this._parsedData !== 'undefined') return this._parsedData
        return new Promise(async resolve => {
            gltfLoader.load(await this.getUrl(), gltf => {
                this._parsedData = { parsedData: convertGltf(gltf), gltf }
                resolve(this._parsedData)
            })
        })
    }

    async getThumbnailUrl() {
        const blob = await this.fs.getFileBlob(this._cdnThumbnailUrl)
        if (blob.size === 0) return undefined
        return this.fs.getObjectUrl(this._cdnThumbnailUrl, blob)
    }

    async getFilesForBundler() {
        const bin = new Uint8Array(await this.getArrayBuffer());
        // Check that the file header is correct: glTF
        const header = (new TextDecoder()).decode(bin.slice(0, 4))
        if (header !== 'glTF') {
            console.error('FsModel3D:getFilesForBundler: Incorrect file header', (new TextDecoder()).decode(bin.slice(0, 10)))
            const blob1 = await this.getBlob()
            const blob2 = await this.fs.getFileBlob(`${this._cdnModelUrl}?no-cache`)
            Sentry.withScope(scope => {
                scope.setExtra('source size', blob2.size)
                scope.setExtra('blob (cached) size', blob1.size)
                scope.setExtra('byteLength', bin.byteLength)
                scope.setExtra('header', header)
                Sentry.captureMessage('Invalid GLB')
            })
        }
        return [{
            path: `fs/${this.id}/model.glb`,
            bin
        }]
    }
}
