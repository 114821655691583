import { IFsFileType } from "@r3f-component-data-structure";
import { FsImage } from "./image";

export class FsImage360 extends FsImage {
    type: IFsFileType.Image360 = IFsFileType.Image360
    protected _filename = 'img.hq'

    constructor(fileId: string, projectId: string, name: string) {
        super(fileId, projectId, name)
        const url = new URL(this.baseUrl)
        url.pathname = `${url.pathname}/${this._filename}`
        this._cdnUrl = url.toString()
    }
}
