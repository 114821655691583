import { IFile, IFileModel3D, IFileStreamingVideo, IFileType } from "@zml-client";
import { IComponentType, IFsFileType } from "@/components/r3f/r3f-components/component-data-structure";
import { filestore } from "../store";
import { convertToImage, convertToModel3d, convertToVideo } from "@/utils";
import { FsImage } from "./image";
import { FsModel3D } from "./model3d";
import { FsVideo } from "./video";
import { FsFile } from ".";

interface FsExternalFileProps {
    source: FsExternalSources
    id: string
    name: string
    thumbnailUrl: string
    videoUrl?: string
    mp3Url?: string
    isAnimated?: boolean
    dimensions?: { x: number, y: number, z: number }
    aspectRatio?: number
}

export enum FsExternalSources {
    Memory = 'memory',
    ZML = 'zml'
}

export class FsExternalFile {
    id: string
    source: FsExternalSources
    name: string
    type: IFsFileType
    thumbnailUrl: string
    videoUrl?: string
    mp3Url?: string
    isAnimated: boolean = false
    dimensions: { x: number, y: number, z: number }
    aspectRatio: number | undefined

    constructor(type: IFsFileType, props: FsExternalFileProps) {
        this.id = props.id
        this.source = props.source
        this.name = props.name
        this.type = type
        this.thumbnailUrl = props.thumbnailUrl
        this.videoUrl = props.videoUrl
        this.mp3Url = props.mp3Url
        this.isAnimated = props.isAnimated || false
        this.dimensions = props.dimensions || { x: 0, y: 0, z: 0 }
        this.aspectRatio = props.aspectRatio
    }

    async import<T extends FsFile>() {
        if (this.source === FsExternalSources.ZML) {
            const fsFile = await filestore.importFromZml(this.id)
            if (typeof fsFile === 'undefined') throw 'Unable to import image from ZML' // TODO: display error message to the user
            return fsFile as T
        }
        throw new Error(`Unsupported source: ${this.source}`)
    }

    async toSpatialComponent(compType?: IComponentType) {
        const fsFile = await this.import()
        if (typeof compType === 'undefined') {
            if (fsFile.type === IFsFileType.Image) {
                compType = IComponentType.Image
            } else if (fsFile.type === IFsFileType.Model3d) {
                compType = IComponentType.Model3d
            } else if (fsFile.type === IFsFileType.Video) {
                compType = IComponentType.Video
            }
        }
        if (compType === IComponentType.Image) return convertToImage(fsFile as FsImage)
        if (compType === IComponentType.Video) return convertToVideo(fsFile as FsVideo)
        if (compType === IComponentType.Model3d) return convertToModel3d(fsFile as FsModel3D, this.dimensions)
        throw `Unable to convert ${this.type} to spatial component ${compType}`
    }
}

export const zmlToExternalFile = (zmlFile: IFile) => {
    const baseUrl = new URL(`${zmlFile.url.startsWith('//') ? 'https:' : ''}${zmlFile.url}`)
    baseUrl.hostname = 'media.zap.works'
    const urlStr = baseUrl.toString()

    const opts = { source: FsExternalSources.ZML, id: zmlFile.id, name: zmlFile.filename, thumbnailUrl: '' }
    if (zmlFile.type === IFileType.Image) {
        return new FsExternalFile(IFsFileType.Image, { ...opts, thumbnailUrl: `${urlStr}/img` })
    }
    if (zmlFile.type === IFileType.Image360) {
        return new FsExternalFile(IFsFileType.Image360, { ...opts, thumbnailUrl: `${urlStr}/img` })
    }
    if (zmlFile.type === IFileType.Model3D) {
        return new FsExternalFile(IFsFileType.Model3d, {
            ...opts,
            thumbnailUrl: `${urlStr}/frame0.png`,
            isAnimated: Boolean((zmlFile as IFileModel3D).output?.animations?.length),
            dimensions: (zmlFile as IFileModel3D).output.dimensions
        })
    }
    if (zmlFile.type === IFileType.StreamingVideo) {
        return new FsExternalFile(IFsFileType.Video, {
            ...opts,
            thumbnailUrl: `${urlStr}/frame0.png`,
            videoUrl: `${urlStr}/video.mp4`,
            // aspectRatio: (zmlFile as IFileStreamingVideo).output.aspectRatio || 1 # Don't use the output of the worker as it contains an invalid value
        })
    }
    if (zmlFile.type === IFileType.Video360) {
        return new FsExternalFile(IFsFileType.Video360, {
            ...opts,
            thumbnailUrl: `${urlStr}/frame0.png`,
            videoUrl: `${urlStr}/video.mp4`,
            // aspectRatio: (zmlFile as IFileStreamingVideo).output.aspectRatio || 1 # Don't use the output of the worker as it contains an invalid value
        })
    }
    if (zmlFile.type === IFileType.Audio) {
        return new FsExternalFile(IFsFileType.Audio, { ...opts, mp3Url: `${urlStr}/aud.mp3` })
    }
    throw new Error(`Unsupported file type: ${zmlFile.type}`)
}

export const fileToExternalFile = (file: File) => {
    const id = crypto.randomUUID()
    const url = filestore.getObjectUrl(id, file)
    let fsFile: FsExternalFile | undefined;
    if (file.type.includes('image')) {
        fsFile = new FsExternalFile(IFsFileType.Image, { source: FsExternalSources.Memory, id, name: file.name, thumbnailUrl: url })
    }
    if (file.type.includes('video')) {
        fsFile = new FsExternalFile(IFsFileType.Video, { source: FsExternalSources.Memory, id, name: file.name, thumbnailUrl: '', videoUrl: url })
    }

    if (typeof fsFile === 'undefined') throw new Error(`Unsupported file type: ${file.type}`)
    filestore.saveExternalFile(id, fsFile)
    return fsFile
}
