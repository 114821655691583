import { COLORS } from '@/utils';
import React from 'react';
import { useSelector } from 'react-redux';
import { IDesignerState, ISpinnerData } from '../../../../typings';
import { ITuple3 } from '../r3f-components/component-data-structure';
import Spinner from '../r3f-components/components/Spinner';


interface IParentProps {
    spinnerDataArray: ISpinnerData[],
    isScreenRelativeSpinners: boolean;
}

const LoadingSpinners: React.FunctionComponent<IParentProps> = ({spinnerDataArray, isScreenRelativeSpinners}) => {
	const isScreenRelativeMode = useSelector((state: IDesignerState) => state.userReducer.isScreenRelativeMode);
    // const activeTrackingType = useGetActiveSceneTrackingType()

    const _spinnerDataArray = spinnerDataArray.filter(spinnerData => spinnerData.isScreenRelative === isScreenRelativeSpinners)
     
    const scale = (isScreenRelativeMode ? [0.1, 0.1, 0.1] : [0.5, 0.5, 0.5] )as ITuple3;
    // if (activeTrackingType && !isScreenRelativeMode) scale = [0.1, 0.1, 0.1] as ITuple3

    return (
        <>
            {_spinnerDataArray.map((spinnerData, index) => {
                const coords = spinnerData.coords;
                const position = isScreenRelativeSpinners ? coords : [coords[0], 0.5, coords[2]] as ITuple3;
                return <group key={index} position={position}>
                    <Spinner opacity={1} radiansPerFrame={0.06} color={COLORS.nebula} scale={scale} renderOrder={Infinity} depthWrite={false} />
                </group>
            })}
        </>
    );
}
export default LoadingSpinners;