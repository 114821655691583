import LoadingSpinners from '@/components/r3f/LoadingSpinners/LoadingSpinners';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { IDesignerState } from '../../../../../../typings';
import { createCanvasR3fComponents, useGetActiveSceneScreenContentId, useGetScreenRelativeDomCanvasAspectRatio } from '../../../../../utils';
import { IScreenAnchorGroup, IScreenContent, ISpatialComponentUnion } from '../../../r3f-components/component-data-structure';
import { getAnchorGroupPrositionDict } from '../../../r3f-components/utils/screen-relative';

const AnchorGroups: React.FunctionComponent = () => {
	const activeScreenContentId = useGetActiveSceneScreenContentId();
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const screenRelativeCanvasAspectRatio = useGetScreenRelativeDomCanvasAspectRatio();
	const anchorGroupPositionDict = getAnchorGroupPrositionDict(screenRelativeCanvasAspectRatio);
	const hiddenEntities = Object.keys(componentsById).filter((entityId) => !!(componentsById[entityId] as ISpatialComponentUnion).isHidden);
	const activeScreenContent = componentsById[activeScreenContentId] as IScreenContent;
    const spinnerData = useSelector((state: IDesignerState) => state.userReducer.spinnerData)

	const anchorGroups = activeScreenContent.children.map((id) => {
		const anchorGroup = componentsById[id] as IScreenAnchorGroup;
		const anchorR3fComponents = createCanvasR3fComponents({
			ids: anchorGroup.children,
			hiddenEntityIds: hiddenEntities,
			componentsById,
		});

		return (
			<group key={anchorGroup.anchorPositionType} name={anchorGroup.anchorPositionType} scale={1} position={anchorGroupPositionDict[anchorGroup.anchorPositionType]}>
				{anchorR3fComponents}
                {anchorGroup.anchorPositionType === 'center' && <LoadingSpinners isScreenRelativeSpinners spinnerDataArray={Object.values(spinnerData)} />}
			</group>
		);
	});

	return <>{anchorGroups}</>;
};

export default memo(AnchorGroups);
