import { filestore, FsModel3D } from '@/filestore';
import { ThreeEvent } from '@react-three/fiber';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDesignerState } from '../../../../typings';
import { onSetActiveAnimationPreset } from '../../../store/actions';
import { ARThreeScene } from '../EditorCanvas/EditorCanvas';
import { IModel3dReactProps, IModel3d, ITuple3 } from '../r3f-components/component-data-structure';
import EntityBoundaries from './EntityBoundaries/EntityBoundaries';
import { useGetHocSpatialProperties, useHocPointerDown, useHocPointerUp } from './hocCustomHooks';
import { getEntityRenderOrder } from './hocUtils';

interface IModel3dWrapped {
	id: string;
	enabled?: boolean;
}

const withModel3dBehaviour = (WrappedModel3d: FunctionComponent<IModel3dReactProps>): FunctionComponent<IModel3dWrapped> => {
	const FuncComp: FunctionComponent<IModel3dWrapped> = ({ enabled: entityIsEnabled = true, id }) => {
		// Selectors
		const dispatch = useDispatch();
		const entity = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById[id] as IModel3d);
		const projectShadowsEnabled =  useSelector((state: IDesignerState) => state.contentReducer.contentDoc.shadowsEnabled ?? true);

		// Refs
		const initialPosRef = useRef<ITuple3 | null>(null); // Used to calculate pointer offset in pointerMove
		const pointerStageRef = useRef<'down' | 'move' | 'up' | null>(null);

		// Entity properties to pass to wrapped component
		const renderOrder = getEntityRenderOrder(entity);
		const { opacity, originalBBox, idleAnimation, idlePoseTime, castShadow = false, receiveShadow = false } = entity;
		const { scale, position, rotation } = useGetHocSpatialProperties(entity);
		const [model3dUrl, setModel3dUrl] = useState<string | undefined>();   
        const [model3dHasError, setModel3dHasError] = useState(false)

        useEffect(() => {
            const fsModel = filestore.load<FsModel3D>(entity.filestoreId)
            fsModel.getUrl()
                .then(url => setModel3dUrl(url))
                .catch(() => setModel3dHasError(true))
		}, [entity.filestoreId])

		// Pointer events
		const onPointerDown = useHocPointerDown({ pointerStageRef, initialPosRef, entity });
		const onPointerUp = useHocPointerUp({ pointerStageRef, initialPosRef, entity });

        const _onPointerDown = (e: ThreeEvent<PointerEvent>) => {
			dispatch(onSetActiveAnimationPreset(false))
			const threeEntity = ARThreeScene.getObjectByName(id);
            const threeEntityInner = threeEntity!.children[0]
			threeEntity?.position.set(...position);
			threeEntity?.rotation.set(...rotation);
			threeEntityInner?.scale.set(1, 1, 1)
			if(entityIsEnabled) onPointerDown(e);
		}

		// If idle pose time is undefined then if there is an idle animation selected, use it's first frame on canvas
		const displayIdlePoseTime = idlePoseTime === undefined ? idleAnimation ? 0 : undefined : idlePoseTime;

		return (
			<>
				<EntityBoundaries
                    forceError={model3dHasError}
                    forceLoading={typeof model3dUrl === 'undefined'}
					position={position}
					rotation={rotation}
					scale={scale}
					renderOrder={renderOrder}
					onPointerDown={_onPointerDown}
					onPointerUp={onPointerUp}
					label={`Model 3D component ${id} render order ${renderOrder}`}
				>
                    <WrappedModel3d
                        id={id}
                        scale={scale}
                        rotation={rotation}
                        position={position}
                        model3dUrl={model3dUrl!}
                        renderOrder={renderOrder}
                        originalBBox={originalBBox}
                        opacity={opacity}
                        castShadow={castShadow && projectShadowsEnabled}
                        receiveShadow={receiveShadow && projectShadowsEnabled}
                        onPointerDown={_onPointerDown}
                        idleAnimation={idleAnimation}
                        idlePoseTime={displayIdlePoseTime}
                        enableBVH={false}
                    />
				</EntityBoundaries>
			</>
		);
	};
	return FuncComp;
};

export default withModel3dBehaviour;
