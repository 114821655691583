import React, { FunctionComponent, Suspense, useMemo } from 'react';
import { ITuple3, ITuple4 } from '../../component-data-structure';
import Spinner from '../Spinner';
// import PlaceholderEntity from './PlaceholderEntity';

interface IParentProps {
	scale: ITuple3;
	rotation: ITuple3;
	position: ITuple3;
	renderOrder: number;
    forceLoading?: boolean;
	children?: JSX.Element | JSX.Element[];
	color?: string;
}

const SuspenseBoundary: FunctionComponent<IParentProps> = ({ forceLoading, scale, rotation, position, renderOrder, children, color = '#5276F7' }) => {
	const mPlc = useMemo(
		() => ({
			scale,
			rotation,
			position,
			fillRgba: [227, 227, 227, 1] as ITuple4,
			borderRgba: [181, 181, 181, 1] as ITuple4,
		}),
		[]
	);

	const spinnerScale = useMemo(() => {
		const isPortrait = scale[1] > scale[0];
		let spScale = [scale[1] * 0.3, scale[1] * 0.3, 0];
		if (isPortrait) spScale = [scale[0] * 0.3, scale[0] * 0.3, 0];
		return spScale as ITuple3;
	}, [scale]);

    const placeholderEntity = (
        <Spinner opacity={1} radiansPerFrame={0.06} color={color} scale={spinnerScale} position={mPlc.position} renderOrder={renderOrder + 2} depthWrite={false} />
    );

	return (
		<Suspense fallback={placeholderEntity}>
			{forceLoading ? placeholderEntity : children}
		</Suspense>
	);
};

export default SuspenseBoundary;
