import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { IDesignerState } from "../../../../../typings";
import Plane from "../../Plane/Plane";
import { ITrackingTypes, ITuple3 } from "../../r3f-components/component-data-structure";
import { ITargetImageReferenceObject } from "../../r3f-components/component-data-structure/types/targetImageReferenceObject";
import EntitySuspenseBoundary from "../../r3f-components/components/boundaries/EntitySuspense";
import map from './map.svg';
import TrackingCurvedReferenceObject from "./TrackingCurvedReferenceObject/TrackingCurvedReferenceObject";
import TrkImage from "./TrackingImage/TrackingImage";
import TrackingRearView from "./TrackingRearView/TrackingRearView";

const ImageTrackingPlaceholder: FunctionComponent = () => {
  const imageTracking = useSelector((state: IDesignerState) => state.contentReducer.contentDoc?.tracking?.[ITrackingTypes.image]);
  const referenceObject = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.targetImageReferenceObject?.referenceObject);
  const targetAreaAspectRatio = useSelector((state: IDesignerState) => state.contentReducer.contentDoc?.tracking?.[ITrackingTypes.image]?.aspectRatio || 1 / 1.41);
  if (typeof imageTracking !== 'undefined' && imageTracking.scale && imageTracking.filestoreId) {
    const { scale } = imageTracking;
    const opacity = imageTracking?.opacity;
    const trkScale: ITuple3 = scale ? [scale[0] * 4, scale[1] * 4, 0] : [4, 4, 0];

    return (
			<EntitySuspenseBoundary scale={trkScale} rotation={[0, 0, 0]} position={[0, 0, 0]} renderOrder={-1}>
        <>
          {typeof referenceObject !== 'undefined' && referenceObject !== ITargetImageReferenceObject.none && <TrackingCurvedReferenceObject key={`${referenceObject}${imageTracking.topRadius}`} />}
					<TrkImage opacity={opacity} referenceObject={referenceObject} />
				</>
			</EntitySuspenseBoundary>
		);
  } else {
    return (
      <>
        <Plane
          name="Tracking Display"
          enabled={false}
          visible={true}
          color={[255, 255, 255, 1]}
          position={[0, 0, -0.011]}
          rotation={[0, 0, 0]}
          scale={[targetAreaAspectRatio * 2, 2, 0]}
          renderOrder={0}
          map={map}
        />
        <TrackingRearView scale={[targetAreaAspectRatio * 2, 2, 0]} />
      </>
    );
  }
};

export default React.memo(ImageTrackingPlaceholder);
